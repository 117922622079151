import { Component,OnInit } from '@angular/core';

import { Router, ActivatedRoute, ParamMap,ActivationStart } from '@angular/router';

import {ConfigService} from './services/config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
   
   name:any;
   currentURL:any;
   myStyles:any ={};
   isActivePin :any={"isSubmit":false};

   

   constructor(  private route: Router,public configService:ConfigService) {
     this.currentURL = window.location.href; 
   }

  title = 'angular-vgs-collect';
  isActive1:boolean = false;
  isActive2:boolean = false;
  isActive3:boolean = false;
  isCardsList:boolean = false;
  


  ngOnInit() {
     this.route.events.subscribe(event =>{
      if (event instanceof ActivationStart){
   		  if(event.snapshot.params.param1==1){
   		      this.myStyles = {background:{backgroundColor: 'gray',
   		      backgroundImage: 'url(https://st.depositphotos.com/1001015/2789/i/950/depositphotos_27893117-stock-photo-credit-card-in-hand.jpg)',
            backgroundRepeat: 'no-repeat, repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover'}
         }
   		  }else if(event.snapshot.params.param1==2){
            this.myStyles = {background:{
   		      backgroundImage: 'url(https://static6.depositphotos.com/1003434/594/i/950/depositphotos_5949969-stock-photo-girl-with-a-credit-card.jpg)',
            backgroundRepeat: 'no-repeat, repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover'}
           }

              //this.myStyles = {background:{backgroundColor: 'rgb(158 146 188)'}}
   		  }else if(event.snapshot.params.param1==3){
   		      this.myStyles = {background:{
   		      backgroundImage: 'url(https://st.depositphotos.com/1015682/1397/i/950/depositphotos_13975311-stock-photo-secure-credit-card-transactions.jpg)',
            backgroundRepeat: 'no-repeat, repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover'}}
            //this.myStyles = {background:{backgroundColor: '#eb9a05'}}
   		  }
      }
   })
   if(this.currentURL.indexOf('/inline')>-1){
      this.isActive1 = true;
      this.isActive2 = false;
      this.isActive3 = false;
      this.isCardsList=false;
    }else if(this.currentURL.indexOf('/cardFrame')>-1){
      this.isActive2 = false;
      this.isActive1 = false;
      this.isActive3 = true;
      this.isCardsList=false;
    }else if(this.currentURL.indexOf('/cards')>-1){
      this.isActive2 = false;
      this.isActive1 = false;
      this.isActive3 = false;
      this.isCardsList=true;
    }else{
      this.isActive2 = true;
      this.isActive1 = false;
      this.isActive3 = false;
      this.isCardsList=false;
    }

    this.configService.selectedCardPin.subscribe((element) => {
        if(element['isSubmit']){
         this.isActivePin = element
       }
    })
 }

 

}
