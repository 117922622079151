import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-card-details-pluto',
  templateUrl: './card-details-pluto.component.html',
  styleUrls: ['./card-details-pluto.component.scss']
})
export class CardDetailsPlutoComponent implements OnInit {

  showText = true;
  card_num_show = 'XXXX XXXX XXXX XXXX';
  exp = 'XX/XX';
  cvv = 'XXX';
  cardType='';
  

  @Input() cardActivateForm;
  @Input() card_detail;
  @Input() errorMessage;
  @Input() myStyles;
  @Input() card_number;

  constructor() { }

  toggleShow(){
    this.showText = !this.showText;
    this.toggleNumber();
    this.toggleExpiry();
    this.toggleCvv();
  }

  toggleNumber(){
    this.card_num_show = '';
    if(!this.showText){
      let number = this.card_detail.card_number.split(' ');
      number.forEach((num,index) => {
        if(index !== number.length - 1){
          this.card_num_show += num.replace(num,'XXXX ');
        }else{
          this.card_num_show += num;
        }
      });
    }else{
      this.card_num_show = this.card_detail.card_number;
    }
  }

  toggleExpiry(){
    this.exp = '';
    this.exp = this.card_detail.exp;
    if(!this.showText){
      let expiry = this.card_detail.exp.split('/');
      this.exp = expiry[0].replace(expiry[0],'\u2022\u2022')+'/'+expiry[1].replace(expiry[1],'\u2022\u2022');
    }else{
      this.exp = this.card_detail.exp;
    }
  }

  toggleCvv(){
    this.cvv = '';
    this.cvv = this.card_detail.cvv;
    if(!this.showText){
      this.cvv = this.card_detail.cvv.replace(this.card_detail.cvv,'\u2022\u2022\u2022');
    }else{
      this.cvv = this.card_detail.cvv;
    }
  }


  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes){
      if(this.card_detail.card_number != undefined){
        this.cardType = this.card_detail.card_number.charAt(0)== 4 ? 'Visa' : this.card_detail.card_number.charAt(0)==5 ? 'Master' : '';
        this.toggleNumber();
        this.toggleExpiry();
        this.toggleCvv();
      }
      // this.exp = this.card_detail.exp;
      // this.cvv = this.card_detail.cvv;
    }
  }

}
