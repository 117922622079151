import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inline-widget',
  templateUrl: './inline-widget.component.html',
  styleUrls: ['./inline-widget.component.scss']
})
export class InlineWidgetComponent implements OnInit {
 public myStyles : any;
 public cardText : any = "Credit Card";
 public isActiveModal  :boolean =false
 public isActiveModal1 :boolean =false
 public isActiveModal2 :boolean =false
 public isActiveModal3 :boolean =false
 public isActiveModal4 :boolean =false
 public isActiveModal5 :boolean =false
 public cardDetails:any;

 
  constructor() { }

  ngOnInit() {
    this.cardDetails={"card_number":'4207-XXXX-XXXX-XX09',"cvv":'XXX',"exp":'07/23'}
      this.myStyles = { 
       title : {
        fontSize : '1.5em',
        },
        background:{
          backgroundColor: 'gray',
          minHeight: '85vh !important'
        },
        borderColor:{
            border:'1px solid green'
        },
        textColor:{
        color:'green'
        }
     }
  }

  openNav() {
    document.getElementById("mySidebar").style.width = "202px";
    document.getElementById("main").style.marginLeft = "250px";
  }
  
  closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("main").style.marginLeft= "0";
  }
  
  backgroundColorChange(event){
     console.log("inisde",event);
     this.myStyles.background.backgroundColor = event;
     this.myStyles.borderColor.backgroundColor =event;;
   }

   textColorChange(event){
     console.log("inisde",event);
     this.myStyles.textColor.color = event;
     this.myStyles.title.color= event;
   }

    borderColorChange(event){
     let color_str ='1px solid '+event
     this.myStyles.borderColor.border = color_str;
   }

    changeFontSize(font_size){
     this.myStyles.title.fontSize= font_size;
    }

    changeFontStyle(font_style){
    this.myStyles.title.fontStyle= font_style;
    this.myStyles.textColor.fontStyle = font_style;
    }

    changeCardText(change_text){
      this.cardText = change_text;
    }

    public showDetails(){
     this.cardDetails={"card_number":'4207 3890 6715 2309',"cvv":'108',"exp":'07/23'}
    }
}
