import {Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-card-details-blink',
  templateUrl: './card-details-blink.component.html',
  styleUrls: ['./card-details-blink.component.scss']
})
export class CardDetailsBlinkComponent implements OnInit {

  showText = false;
  alertMessage = '';
  counter = 0;
  cardValue;
  card_rem_digit;
  card_holder_name = 'JAKE EL MIR';
  card_num_show = 'xxxx xxxx xxxx xxxx';
  exp = 'xx/xx';
  cvv = 'xxx';
  public card_name: boolean = false;
  public card_num: boolean = false;
  public expiry: boolean = false;
  public security_code: boolean = false;


  @Input() cardActivateForm;
  @Input() card_detail;
  @Input() errorMessage;
  @Input() myStyles;
  @Input() card_number;

  constructor() { }

  ngOnInit() {
  }

  copyToClipboard(item,value): void {
    if(value == 'card_name'){
      this.card_name = true;
      this.card_num = false;
    }else if(value == 'card_num'){
      this.card_num = true;
      this.card_name = false;
    }else if(value == 'expiry'){
      this.expiry = true;
      this.card_name = false;
    }else{
      this.security_code = true;
      this.card_name = false;
    } 
    item.select();
    document.execCommand('copy');
    item.setSelectionRange(0, 0);
    navigator.clipboard.writeText(item.value.replace(/\s/g, ''));
      setTimeout(()=>{
            this.card_name = false;
            this.card_num = false;
            this.expiry = false;
            this.security_code = false;
          }, 1000);
  }

}
