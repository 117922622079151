/*import { InlineWidgetComponent } from '../inline-widget/inline-widget.component';
import { Component, OnInit, ElementRef, ViewChild, ComponentRef, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';*/

import { Component,OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import {ConfigService} from '../../services/config.service';

import {PciWidgetService} from '../../services/pci-widget.service';

import { interval } from 'rxjs';

import { environment } from '../../../environments/environment';


import { Router, ActivatedRoute, ParamMap,ActivationStart } from '@angular/router';

const sourceID = environment.source_id;

@Component({
  selector: 'app-card-iframe',
  templateUrl: './card-iframe.component.html',
  styleUrls: ['./card-iframe.component.scss']
})
export class CardIframeComponent implements OnInit {
  
  public cardDetails:any;
  public client1Logo:any
  slideIndex = 1;
  public isIframeLoaded :boolean;

  //@ViewChild('iframe', {static: false}) iframe: ElementRef;
    url: string ;
    urlSafe: SafeResourceUrl;
    lutToken:any;
    cardId:any;
    isLoaded:boolean=true;

  
  constructor(public sanitizer: DomSanitizer,
              private route:Router,
              public configService:ConfigService,
              private _route: ActivatedRoute,
              private pciWidgetService:PciWidgetService)
               {}


  ngOnInit() {
     this._route.paramMap.subscribe((params: ParamMap) => {
          let pci_info =  JSON.parse(atob(params.get("token")));
          let pci_bg_color      =    pci_info['background-color'];
          let pci_btn_bg_color  =    pci_info['button-background-color'];
          let pci_btn_txt_color =    pci_info['button-text-color'];
          let pci_err_color     =    pci_info['error-color'];
          let pci_txt_color     =    pci_info['text-color'];
          this.lutToken         =     pci_info['lut_token'];
          this.cardId =  params.get('card_id');
          if(this.lutToken && this.cardId){
            let card_object = {"card_id":this.cardId ,"lut":this.lutToken,"source_id":sourceID,
            "background-color" : pci_bg_color ,"button-background-color" : pci_btn_bg_color, "button-text-color" : pci_btn_txt_color,
            "error-color" : pci_err_color, "text-color" : pci_txt_color};
            this.url = environment.iframe_url+btoa(JSON.stringify(card_object));
            this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
           }
       });

    }
}
