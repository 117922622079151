import {Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-card-details-nublo',
  templateUrl: './card-details-nublo.component.html',
  styleUrls: ['./card-details-nublo.component.scss']
})
export class CardDetailsNubloComponent implements OnInit {
  showText = true;
  card_num_show = "XXXX XXXX XXXX XXXX";
  exp = "XX/XX";
  cvv = "XXX";
  // public card_name: boolean = false;
  public card_num: boolean = false;
  public expiry_num: boolean = false;
  public security_code: boolean = false;

  @Input() cardActivateForm;
  @Input() card_detail;
  @Input() errorMessage;
  @Input() myStyles;
  @Input() card_number;

  constructor() { }
  toggleShow() {
    this.showText = this.showText;
    this.toggleNumber();
    this.toggleExpiry();
    this.toggleCvv();
  }
  toggleNumber() {
    this.card_num_show = "";
    if (!this.showText) {
      if (Object.keys(this.card_detail).length > 0) {
        let number = this.card_detail.card_number.split(" ");
        number.forEach((num, index) => {
          if (index !== number.length - 1) {
            this.card_num_show += num.replace(num, "\u2022\u2022\u2022\u2022 ");
          } else {
            this.card_num_show += num;
          }
        });
      }
    } else {
      this.card_num_show = this.card_detail.card_number;
    }
  }

  toggleExpiry(){
    this.exp = '';
    this.exp = this.card_detail.exp;
    if(!this.showText && this.card_detail.exp){
      let expiry = this.card_detail.exp.split('/');
      this.exp = expiry[0].replace(expiry[0],'\u2022\u2022')+'/'+expiry[1].replace(expiry[1],'\u2022\u2022');
    }else{
      this.exp = this.card_detail.exp;
    }
  }

  toggleCvv() {
    this.cvv = "";
    this.cvv = this.card_detail.cvv;
    if (!this.showText && Object.keys(this.card_detail).length > 0) {
      this.cvv = this.card_detail.cvv.replace(
        this.card_detail.cvv,
        "\u2022\u2022\u2022"
      );
    } else {
      this.cvv = this.card_detail.cvv;
    }
  }

  ngOnInit() {
    this.toggleShow();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (this.card_detail.card_number != undefined) {
        this.toggleNumber();
        this.toggleExpiry();
        this.toggleCvv();
      }
      // this.exp = this.card_detail.exp;
      // this.cvv = this.card_detail.cvv;
    }
  }

  copyToClipboard(item, value): void {
    if (value == "card_num") {
      this.card_num = true;
      this.security_code = false;
      this.expiry_num = false;
    }else if(value == "expiry_num"){
      this.expiry_num = true;
      this.card_num = false;
      this.security_code = false;
    }else {
      this.security_code = true;
      this.card_num = false;
      this.expiry_num = false;
    }
    item.select();
    document.execCommand("copy");
    item.setSelectionRange(0, 0);
    navigator.clipboard.writeText(item.value.replace(/\s/g, ""));
    setTimeout(() => {
      this.card_num = false;
      this.security_code = false;
      this.expiry_num = false;
    }, 1000);
  }

}
