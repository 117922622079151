import { Injectable } from '@angular/core';

import{BehaviorSubject, Subject} from "rxjs"


@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  public client1:any;
  public client2:any;
  public client3:any

  private submitCardActivate = new BehaviorSubject<Object>({});
  selectedCardPin = this.submitCardActivate.asObservable();

  constructor() { 

   //client1 config object
   
    this.client1 = {
       title : {
        fontSize : '1.5em',
        color:'black',
        fontStyle:'normal'
        },
        background:{
          // backgroundColor: 'white',
          //minHeight: '85vh !important'
        },
        borderColor:{
            border:'1px solid green'
        },
        cardNumber:{
         fontSize : '15px',
         color:'orange',
         fontStyle:'normal'
        },
        cvv:{
         fontSize : '15px',
         color:'tan',
         fontStyle:'italic',
         textIndent:'30px',
        },
        exp:{
         fontSize : '15px',
         color:'black',
         fontStyle:'italic',
         textIndent:'30px',
        },
        error:{
         fontSize : '15px',
         color:'red',
         fontStyle:'normal'
        },
        cardRow:{
          backgroundColor: '#364fa0',
          borderRadius:'17px',
        },
         iframeWidth:{
        width:'670px'
        }



     };


     //client2 config object 

     this.client2 ={
      title : {
        fontSize : '0px',
        color:'black',
        fontStyle:'normal'
        },
        background:{
          // backgroundColor: 'rgb(158 146 188)',
          //minHeight: '85vh !important'
        },
        borderColor:{
            border:'1px solid green'
        },
        cardNumber:{
         fontSize : '30px',
         color:'white',
         fontStyle:'italic'
        },
        cvv:{
         fontSize : '1.5em',
         color:'white',
         fontStyle:'italic',
         textIndent:'10px',
        },
        exp:{
         fontSize : '1.5em',
         color:'white',
         fontStyle:'italic',
         textIndent:'15px',
        },
        error:{
         fontSize : '15px',
         color:'red',
         fontStyle:'normal'
        },
        cardRow:{
          backgroundColor: 'black',
          borderRadius:'23px',
        },
        iframeWidth:{
        width:'800px'
        }

     };

  

  //client3 config object

     this.client3 = {
      title : {
        fontSize : '1.5em',
        color:'black',
        fontStyle:'italic'
        },
        background:{
          // backgroundColor: '#eb9a05',
          //minHeight: '85vh !important'
        },
        borderColor:{
            border:'1px solid green'
        },
        cardNumber:{
         fontSize : '1.5em',
         color:'green',
         fontStyle:'italic',
        },
        cvv:{
         fontSize : '1.5em',
         color:'yellow',
         fontStyle:'italic',
         textIndent:'20px',
        },
        exp:{
         fontSize : '1.3em',
         color:'black',
         fontStyle:'italic',
         textIndent:'1px',
        },
        error:{
         fontSize : '15px',
         color:'red',
         fontStyle:'normal'
        },
       cardRow:{
          backgroundColor: 'tan',
        },
        iframeWidth:{
        width:'800px'
        }
     }
  }
  



 public getClientConfig(clientId):any{
    if(clientId==1){
       return this.client1;
     }else if(clientId==2){
       return this.client2;
     }else{
       return this.client3;
     }
   }

   submitActivateCardAction(event){
   this.submitCardActivate.next(event)
   }
}
