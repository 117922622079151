import {Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-card-details-pemo',
  templateUrl: './card-details-pemo.component.html',
  styleUrls: ['./card-details-pemo.component.scss']
})
export class CardDetailsPemoComponent implements OnChanges {

  showText = false;
  alertMessage = '';
  counter = 0;
  cardValue;
  card_rem_digit;
  card_num_show = 'xxxx xxxx xxxx xxxx';
  exp = 'xx/xx';
  cvv = 'xxx';
  // public isVisible: boolean = false;
  // public isVisible: boolean = false;
  public card_num: boolean = false;
  public expiry: boolean = false;

  @Input() cardActivateForm;
  @Input() card_detail;
  @Input() errorMessage;
  @Input() myStyles;
  @Input() card_number;

  
  constructor() {
  }

  toggleShow(){
    this.showText = !this.showText;
    this.toggleNumber();
    this.toggleExpiry();
    this.toggleCvv();
  }

  toggleNumber(){
    this.card_num_show = '';
    this.card_num_show = this.card_detail.card_number;
    // if(!this.showText){
    //   let number = this.card_detail.card_number.split(' ');
    //   number.forEach((num,index) => {
    //     if(index !== number.length - 1){
    //       this.card_num_show += num.replace(num,'\u2022\u2022\u2022\u2022 ');
    //     }else{
    //       this.card_num_show += num;
    //     }
    //   });
    // }else{
    //   this.card_num_show = this.card_detail.card_number;
    // }
  }

  toggleExpiry(){
    this.exp = '';
    this.exp = this.card_detail.exp;
    // if(!this.showText){
    //   let expiry = this.card_detail.exp.split('/');
    //   this.exp = expiry[0].replace(expiry[0],'\u2022\u2022')+'/'+expiry[1].replace(expiry[1],'\u2022\u2022');
    // }else{
    //   this.exp = this.card_detail.exp;
    // }
  }

  toggleCvv(){
    this.cvv = '';
    this.cvv = this.card_detail.cvv;
    // if(!this.showText){
    //   this.cvv = this.card_detail.cvv.replace(this.card_detail.cvv,'\u2022\u2022\u2022');
    // }else{
    //   this.cvv = this.card_detail.cvv;
    // }
  }


  copyToClipboard(item,value): void {
    if(value == 'card_num'){
      this.card_num = true;
    }else{
      this.expiry = true;
    } 
    item.select();
    document.execCommand('copy');
    item.setSelectionRange(0, 0);
    navigator.clipboard.writeText(item.value.replace(/\s/g, ''));
    // document.addEventListener('copy', (e: ClipboardEvent) => {
    //   e.clipboardData.setData('text/plain', (item));
    //   e.preventDefault();
    //   document.removeEventListener('copy', null);
    // });
    //  document.execCommand('copy'); 
      setTimeout(()=>{
            this.card_num = false;
            this.expiry = false;
          }, 1000);
  }


  ngOnChanges(changes: SimpleChanges): void {
    if(changes){
      if(this.card_detail.card_number != undefined && this.card_detail.exp != undefined && this.card_detail.cvv != undefined){
        this.toggleNumber();
        this.toggleExpiry();
        this.toggleCvv();
      }
      // this.exp = this.card_detail.exp;
      // this.cvv = this.card_detail.cvv;
    }
  }

}

