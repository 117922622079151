import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule ,ReactiveFormsModule} from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule, HttpClient} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { VgsWidgetComponent } from './components/vgs/vgs-widget/vgs-widget.component';
import { InlineWidgetComponent } from './components/inline-widget/inline-widget.component';
import { CardWidgetComponent } from './components/card-widget/card-widget.component';
import { CardFormWidgetComponent } from './components/card-form-widget/card-form-widget.component';
import { CardIframeComponent } from './components/card-iframe/card-iframe.component';
import { SeperateInlineComponent } from './components/seperate-inline/seperate-inline.component';
import { CardActivateComponent } from './components/card-activate/card-activate.component';
import { CardListComponent } from './components/card-list/card-list.component';
import { RevealCardDetailsComponent } from './components/reveal-card-details/reveal-card-details.component';
import { CardGetPinComponent } from './components/card-get-pin/card-get-pin.component';
import { ChangeCardPinComponent } from './components/change-card-pin/change-card-pin.component';
import { CardShowPinFrameComponent } from './components/card-show-pin-frame/card-show-pin-frame.component';
import { CardSetPinFrameComponent } from './components/card-set-pin-frame/card-set-pin-frame.component';
import { CardTenant2Component } from './components/card-tenant2/card-tenant2.component';
import { CardTenant35Component } from './components/card-tenant35/card-tenant35.component';
import { CardTenant4Component } from './components/card-tenant4/card-tenant4.component';
import { CardTenant36Component } from './components/card-tenant36/card-tenant36.component';
import { CardDetailsPemoComponent } from './components/card-details-pemo/card-details-pemo.component';
import { CardDetailsSavMoneyComponent } from './components/card-details-sav-money/card-details-sav-money.component';
import { CardDetailsSaviiComponent } from './components/card-details-savii/card-details-savii.component';
import { CardDetailsBlinkComponent } from './components/card-details-blink/card-details-blink.component';
import { CardDetailsBankiomComponent } from './components/card-details-bankiom/card-details-bankiom.component';
import { CardDetailsPlutoComponent } from './components/card-details-pluto/card-details-pluto.component';
import { CardDetailsFinwellComponent } from './components/card-details-finwell/card-details-finwell.component';
import { CardDetailsZywaComponent } from './components/card-details-zywa/card-details-zywa.component';
import { CardDetailsEasytipComponent } from './components/card-details-easytip/card-details-easytip.component';
import { CardDetailsMyffinComponent } from './components/card-details-myffin/card-details-myffin.component';
import { CardDetailsMamoComponent } from './components/card-details-mamo/card-details-mamo.component';
import { CardDetailsCreditperComponent } from './components/card-details-creditper/card-details-creditper.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { CardDetailsAkeedpayComponent } from './components/card-details-akeedpay/card-details-akeedpay.component';
import { CardDetailsNubloComponent } from './components/card-details-nublo/card-details-nublo.component';
import { CardDetailsHugoComponent } from './components/card-details-hugo/card-details-hugo.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const I18N_CONFIG = {
  defaultLanguage: 'en',
  loader: {
    provide: TranslateLoader,
    useFactory: HttpLoaderFactory,
    deps: [HttpClient]
  }
}

@NgModule({
  declarations: [
    AppComponent,
    VgsWidgetComponent,
    InlineWidgetComponent,
    CardWidgetComponent,
    CardFormWidgetComponent,
    CardIframeComponent,
    SeperateInlineComponent,
    CardActivateComponent,
    CardListComponent,
    RevealCardDetailsComponent,
    CardGetPinComponent,
    ChangeCardPinComponent,
    CardShowPinFrameComponent,
    CardSetPinFrameComponent,
    CardTenant2Component,
    CardTenant35Component,
    CardTenant4Component,
    CardTenant36Component,
    CardDetailsPemoComponent,
    CardDetailsSavMoneyComponent,
    CardDetailsSaviiComponent,
    CardDetailsBlinkComponent,
    CardDetailsBankiomComponent,
    CardDetailsPlutoComponent,
    CardDetailsFinwellComponent,
    CardDetailsZywaComponent,
    CardDetailsEasytipComponent,
    CardDetailsMyffinComponent,
    CardDetailsMamoComponent,
    CardDetailsCreditperComponent,
    CardDetailsAkeedpayComponent,
    CardDetailsNubloComponent,
    CardDetailsHugoComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    HttpClientModule,
    AppRoutingModule,
    TranslateModule.forRoot(I18N_CONFIG),
  ],
  providers: [HttpClientModule],
  bootstrap: [AppComponent]
})
export class AppModule { }
