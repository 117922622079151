import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card-tenant35',
  templateUrl: './card-tenant35.component.html',
  styleUrls: ['./card-tenant35.component.scss']
})
export class CardTenant35Component implements OnInit {

  @Input() cardActivateForm;
  @Input() card_detail;
  @Input() errorMessage;
  @Input() myStyles;
  
  constructor() { }

  ngOnInit() {
  }

}
