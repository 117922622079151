import { Component, OnInit, ViewChildren } from "@angular/core";
import { PciWidgetService } from "../../services/pci-widget.service";

import { ActivatedRoute, ParamMap, Router } from "@angular/router";

import { environment } from "../../../environments/environment";

import * as forge from "../../services/forge.js";

import { isNumeric } from "rxjs/util/isNumeric";

import {
  FormBuilder,
  Validators,
  AbstractControl,
  FormGroup,
  FormControl,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-change-card-pin",
  templateUrl: "./change-card-pin.component.html",
  styleUrls: ["./change-card-pin.component.scss"],
})
export class ChangeCardPinComponent implements OnInit{
  [x: string]: any;
  cardId;
  checkSame;
  cardLut;
  isLoaded: boolean = true;
  cardData: any;
  errMesaage: any;
  successMessage: any;
  tenantID: any;
  createPin = false;
  btnEnable = true;
  errorToShow: boolean = false;
  pinCorrect: boolean = false;
  errorMsg: string = "";
  weak_pass = false;
  clicked = false;
  create_pin_length: any = "1";
  confirm_pin_length: any = "1";
  s_hide_show_mamo : boolean = true;
  c_hide_show_mamo : boolean = true;
  bgColor: string = "";
  txtColor: string = "";
  errColor: string = "";
  btnBgColor: string = "";
  btnTextColor: string = "";
  public form: FormGroup;
  arrVal:any=[];
  arrVal1:any=[];

  arabicEye='';
  arabicText='';
  textRight='';
  arabicTextSize = '';
  lang='en';

  passwordFieldType: string = 'password';
  constructor(
    public pciWidgetService: PciWidgetService,
    public router: Router,
    private _route: ActivatedRoute,
    private fb: FormBuilder,
    private translate: TranslateService
  ) {
      translate.setDefaultLang('en');
    }

  @ViewChildren("formRow") rows: any;
  @ViewChildren("formRow1") rows1: any;

  changePinFormLeap = this.fb.group({
    // For Leap Design Inputs Added
    change1: ["", [Validators.required]],
    change2: ["", [Validators.required]],
    change3: ["", [Validators.required]],
    change4: ["", [Validators.required]],
  });
  confirmPinFormLeap = this.fb.group({
    // For Leap Design Inputs Added
    confirm1: ["", [Validators.required]],
    confirm2: ["", [Validators.required]],
    confirm3: ["", [Validators.required]],
    confirm4: ["", [Validators.required]],
  });
  // End Here For Leap Design Inputs Added
  // changePinForm = this.fb.group(
  //   {
  //     change: [
  //       "",
  //       [
  //         Validators.required,
  //         // Validators.pattern('^([1-9]{4}|[1-9]{12})$'),
  //         Validators.pattern("^[0-9]{4,12}$"),
  //         this.validateSequence,
  //         // this.validateZero,
  //       ],
  //     ],
  //     confirm: ["", [Validators.required]],
  //   },
  //   { validators: this.validatePin }
  // );
  changePinForm = this.fb.group(
    {
      change_show:[''],
      change: [
        "",
        [
          Validators.required,
          Validators.pattern("^[0-9]{4,12}$"),
          this.validateSequence,
        ],
      ],
      confirm_show:[''],
      confirm: ["", [Validators.required]],
    },
    { validators: this.validatePin }
  );

  changePinFormSavii = this.fb.group(
    {
      change: [
        "",
        [
          Validators.required,
          // Validators.pattern('^([1-9]{4}|[1-9]{12})$'),
          Validators.pattern("^[0-9]{4,4}$"),
          //this.validateSequence,
          // this.validateZero,
        ],
      ],
      confirm: ["", [Validators.required]],
    },
    { validators: this.validatePin }
  );

  ngOnInit() {
    this.isLoaded=true;
    this._route.paramMap.subscribe((params: ParamMap) => {
      this.cardData = JSON.parse(atob(params.get("token")));
      this.bgColor          = this.cardData['background-color']; 
      this.txtColor         = this.cardData['text-color']
      this.errColor         = this.cardData['error-color'];
      this.btnBgColor       = this.cardData['button-background-color'];
      this.btnTextColor     = this.cardData['button-text-color'];
      this.lang = this.cardData['lang'];
      if(this.lang != ''){
        if(this.lang == 'ar'){
          this.arabicEye = 'arabic';
          this.arabicText = 'textArabic';
          this.textRight = 'text-end';
          this.arabicTextSize = 'arabic-font-size'
    
        }else{
          this.arabicEye = '';
          this.arabicText = '';
          this.textRight = '';
          this.arabicTextSize = ''
        }
        this.translate.setDefaultLang(this.lang);
      }
    });
    this.pciWidgetService
      .getTenenatId(
        this.cardData.card_id,
        this.cardData.lut,
        this.cardData.source_id
      )
      .subscribe(
        (res) => {
          let client_details = environment.tenantDetails.filter(
            (tenant) => tenant.tenantID == res["tenant_id"]
          );
          if (client_details.length > 0) {
            this.tenantID = client_details[0];
          } else {
            this.tenantID = {
              tenantID: "default",
              clientName: "default",
              card_reveal_private_key: "",
              public_key: "",
              private_key: "",
            };
          }
          //this.tenantID = res['tenant_id']
          this.isLoaded = false;
        },
        (error) => {
          this.isLoaded = false;
        }
      );

    this.changePinForm.valueChanges.subscribe((x) => {
      // console.log('form value changed')
      // console.log(this.changePinForm)
    });
  }
  changeInput(){
    this.changePinForm.patchValue({
      change:this.toNumeric(this.changePinForm.value.change_show),
      confirm:this.toNumeric(this.changePinForm.value.confirm_show),
      change_show:this.lang !=='' && this.lang !== 'en' ? this.toArabicNumerals(this.changePinForm.value.change_show) : this.changePinForm.value.change_show,
      confirm_show:this.lang !=='' && this.lang !== 'en' ? this.toArabicNumerals(this.changePinForm.value.confirm_show) : this.changePinForm.value.confirm_show,
    });
  }

  toArabicNumerals(value: any) {
    return value.replace(/\d/g, (digit) => '٠١٢٣٤٥٦٧٨٩'[parseInt(digit)]);
  }

  toNumeric(value: any) {
    return value.replace(/[٠١٢٣٤٥٦٧٨٩]/g, (digit) => '0123456789'['٠١٢٣٤٥٦٧٨٩'.indexOf(digit)]);
  }

  changeCardPin() {
    this.isLoaded = true;
    var encode_pin = "";
    var pin = "";
    if (this.tenantID && this.tenantID.clientName == "Savii") {
      pin = this.changePinFormSavii.value.change.toString();
    } else {
      pin = this.changePinForm.value.change.toString();
    }
    if (environment.env_type == "Production" || environment.env_type == "Ksa" || environment.env_type == "Iq" || environment.env_type == "Pak") {
      encode_pin = forge.util.pci_encrypt2(this.tenantID.public_key, pin);
    } else if (environment.env_type == "Egypt") {
      encode_pin = forge.util.pci_encrypt(this.tenantID.public_key, pin);
    } else {
      encode_pin = forge.util.pci_encrypt2(environment.public_key, pin);
    }
    this.pciWidgetService
      .changePin(this.cardData.card_id, this.cardData.lut, encode_pin)
      .subscribe(
        (response) => {
          this.clicked = false;
          if (this.tenantID && this.tenantID.clientName == "Savii") {
            this.isLoaded = false;
            window.parent.location.href = "https://savii://success";
          }
          else if (this.tenantID && this.tenantID.clientName == "Bankiom") {
            this.isLoaded = false;
            let payload = {
              type: "SUCCESS",
              message: "Card pin reset successfully",
            };
            this.postMessage(payload);
          } 
          else {
            this.successMessage = "Card pin reset successfully";
            this.isLoaded = false;
            let payload = {
              type: "SUCCESS",
              message: "Card pin reset successfully",
            };
            window.parent.postMessage(JSON.stringify(payload), '*');
            this.postMessage(payload);
          }
        },
        (error) => {
          console.log("error", error);
          if (this.tenantID && this.tenantID.clientName == "Savii") {
            this.isLoaded = false;
            let payload = { type: "ERROR", message: error.error.detail };
            window.parent.postMessage(JSON.stringify(payload), window.origin);
            window.parent.location.href = "https://savii://failure";
          } else if(this.tenantID && this.tenantID.clientName == "Bankiom"){
            let payload = { type: "ERROR", message: error.error.detail };
            this.postMessage(payload);
            this.isLoaded = false;
          }else {
            this.errMesaage = error.error.detail;
            this.isLoaded = false;
            let payload = { type: "ERROR", message: error.error.detail };
            window.parent.postMessage(JSON.stringify(payload), '*');
            this.postMessage(payload);
            

          }
        }
      );
  }

  validatePin(control: FormGroup) {
    const password = control.get("change");
    const repeatPassword = control.get("confirm");
    if (repeatPassword.value != "" && repeatPassword.value) {
      return password.value != repeatPassword.value
        ? { passwordError: true }
        : null;
    }
    return null;
  }

  validateSequence(control: AbstractControl) {
    let value = control.value;
    if (value != null) {
      let digits = value.toString().split("");
      // console.log(digits,'Digits')
      // Iterate through pairs of values
      // As long as one comparison is not consecutive, the PIN is valid
      if (digits.length >= 4 && digits.length <= 12) {
        for (var i = 0; i < digits.length - 1; i++) {
          if (Math.abs(parseInt(digits[i]) - parseInt(digits[i + 1])) > 1) {
            return null;
            // break;
          }
        }
        return { expPatteren: true };
      }
      return null;
    }
  }

  continue() {
    this.createPin = !this.createPin;
    this.btnEnable = true;
  }

  // New Code For Pin
  // Create Pin Start Here
  keyUpEventForCreatePin(event, index) {
    let pos = index;
    this.create_pin_length =
      this.changePinFormLeap.get("change1").value +
      "" +
      this.changePinFormLeap.get("change2").value +
      "" +
      this.changePinFormLeap.get("change3").value +
      "" +
      this.changePinFormLeap.get("change4").value;
      if(this.changePinFormLeap.get(`change${index + 1}`).value){
        this.changePinFormLeap.get(`change${index + 1}`).setValue("");
      }
    var charCode = event.which ? event.which : event.keyCode;
    if ((charCode < 48 || charCode > 57) && event.which != 8) {
      this.btnEnable = true;
      if (charCode == 9) {
        this.errorToShow = true;
        this.errorMsg = "This field is required";
        this.btnEnable = true;
      } else {
        this.errorToShow = false;
        this.errorMsg = "";
      }
    } else {
      if(event.key!=='Backspace'){
         this.changePinFormLeap.get(`change${index + 1}`).setValue(event.key);
       }
      if (event.keyCode === 8 && event.which === 8) {
        this.btnEnable = true;
        pos = index - 1;
      } else {
        pos = index + 1;
      }
      if (pos > -1 && pos < 4) {
        this.errorToShow = true;
        this.errorMsg = "This field is required";
        this.btnEnable = true;
        this.rows._results[pos].nativeElement.focus();
      } else {
        this.btnEnable = true;
        this.errorToShow = false;
        this.errorMsg = "";
        this.validateSequenceLeap();
      }
      if (pos > 3 && this.changePinFormLeap.get("change4").value == "") {
        this.errorToShow = true;
        this.errorMsg = "This field is required";
        this.btnEnable = true;
      } else {
        this.errorToShow = false;
        this.errorMsg = "";
      }
    }
  }
  validateSequenceLeap() {
    var change1 = this.changePinFormLeap.get("change1").value;
    var change2 = this.changePinFormLeap.get("change2").value;
    var change3 = this.changePinFormLeap.get("change3").value;
    var change4 = this.changePinFormLeap.get("change4").value;
    var value = change1 + "" + change2 + "" + change3 + "" + change4;
    if (value != "Backspace") {
      if(value == ''){
        this.weak_pass = false;
      }
      if (value != null) {
        let digits = value.toString().split("");
        if (digits.length >= 4 && digits.length <= 12) {
          for (var i = 0; i < digits.length - 1; i++) {
            if (Math.abs(parseInt(digits[i]) - parseInt(digits[i + 1])) > 1) {
              this.btnEnable = false;
              this.weak_pass = false;
              this.createPin = true;
              return null;
            }
          }
          return (this.weak_pass = true);
        }
        return null;
      }
    } else {
      this.btnEnable = true;
    }
  }
  // Create Pin End Here

  // Confirm Pin Start Here
  keyUpEventForConfirmPin(event, index) {
    let pos = index;
    this.confirm_pin_length =
      this.confirmPinFormLeap.get("confirm1").value +
      "" +
      this.confirmPinFormLeap.get("confirm2").value +
      "" +
      this.confirmPinFormLeap.get("confirm3").value +
      "" +
      this.confirmPinFormLeap.get("confirm4").value;
    var charCode = event.which ? event.which : event.keyCode;

    if ((charCode < 48 || charCode > 57) && event.which != 8) {
      this.btnEnable = true;
      if (charCode == 9) {
        this.errorToShow = true;
        this.errorMsg = "This field is required";
        this.btnEnable = true;
      } else {
        this.errorToShow = false;
        this.errorMsg = "";
      }
    } else {
      if(event.key!=='Backspace'){
        this.confirmPinFormLeap.get(`confirm${index + 1}`).setValue(event.key);
      }
      if (event.keyCode === 8 && event.which === 8) {
        this.btnEnable = true;
        pos = index - 1;
      } else {
        pos = index + 1;
      }
      if (pos > -1 && pos < 4) {
        this.errorToShow = true;
        this.errorMsg = "This field is required";
        this.btnEnable = true;
        if (this.tenantID.clientName == "Zywa") {
          this.rows1._results[pos].nativeElement.focus();
        } else {
          this.rows._results[pos].nativeElement.focus();
        }
      } else {
        this.btnEnable = true;
        this.errorToShow = false;
        this.errorMsg = "";
        this.validatePinLeap();
      }
      if (pos > 3 && this.confirmPinFormLeap.get("confirm4").value == "") {
        this.errorToShow = true;
        this.errorMsg = "This field is required";
        this.btnEnable = true;
      } else {
        this.errorToShow = false;
        this.errorMsg = "";
      }
    }
  }

  validatePinLeap() {
    var change1 = this.changePinFormLeap.get("change1").value;
    var change2 = this.changePinFormLeap.get("change2").value;
    var change3 = this.changePinFormLeap.get("change3").value;
    var change4 = this.changePinFormLeap.get("change4").value;
    var changePin = change1 + "" + change2 + "" + change3 + "" + change4;
    var confirm1 = this.confirmPinFormLeap.get("confirm1").value;
    var confirm2 = this.confirmPinFormLeap.get("confirm2").value;
    var confirm3 = this.confirmPinFormLeap.get("confirm3").value;
    var confirm4 = this.confirmPinFormLeap.get("confirm4").value;
    var confirmPin = confirm1 + "" + confirm2 + "" + confirm3 + "" + confirm4;
    if (changePin != confirmPin) {
      this.btnEnable = true;
      return (this.pinCorrect = true);
    } else {
      this.btnEnable = false;
      return (this.pinCorrect = false);
    }
  }

  // Ideal implementation
  postMessage(message) {
    // // 1. Relay the event back to the host application
    if (window["webkit"]) {
      window["webkit"].messageHandlers.widgetEventCallback.postMessage(message);
    } // iOS support

    if (window["Android"]) {
      // Object Android is available in the global namespace of the host frame
      window["Android"].widgetEventCallback(JSON.stringify(message));
    }
  }
  

  changeCardPinLeap() {
    var confirm1 = this.confirmPinFormLeap.get("confirm1").value;
    var confirm2 = this.confirmPinFormLeap.get("confirm2").value;
    var confirm3 = this.confirmPinFormLeap.get("confirm3").value;
    var confirm4 = this.confirmPinFormLeap.get("confirm4").value;
    var confirmPin = confirm1 + "" + confirm2 + "" + confirm3 + "" + confirm4;

    this.isLoaded = true;
    var encode_pin = "";
    if (environment.env_type == "Production") {
      encode_pin = forge.util.pci_encrypt2(
        this.tenantID.public_key,
        confirmPin.toString()
      );
    } else {
      encode_pin = forge.util.pci_encrypt2(
        environment.public_key,
        confirmPin.toString()
      );
    }

    this.pciWidgetService
      .changePin(this.cardData.card_id, this.cardData.lut, encode_pin)
      .subscribe(
        (response) => {
          if (this.tenantID && this.tenantID.clientName == "Zywa") {
            this.isLoaded = false;
            let payload = {
              type: "SUCCESS",
              message: "Card pin reset successfully",
            };
            this.postMessage(payload);
          } else if (this.tenantID && this.tenantID.clientName == "Bayblos" || this.tenantID && this.tenantID.clientName == "Hugo" ) {
            this.isLoaded = false;
            let payload = {
              type: "SUCCESS",
              message: "Card pin reset successfully",
            };
            this.postMessage(payload);
          } else {
            this.successMessage = "Card pin reset successfully";
            this.isLoaded = false;
          }
        },
        (error) => {
          if (this.tenantID && this.tenantID.clientName == "Zywa") {
            this.isLoaded = false;
            let payload = { type: "ERROR", message: error.error.detail };
            this.postMessage(payload);
          } else if (this.tenantID && this.tenantID.clientName == "Bayblos" || this.tenantID && this.tenantID.clientName == "Hugo" ) {
            this.isLoaded = false;
            let payload = { type: "ERROR", message: error.error.detail };
            this.postMessage(payload);
          }else {
            this.errMesaage = error.error.detail;
            this.isLoaded = false;
          }
        }
      );
  }

  keyUpEventForCreatePinZywa(event, index) {
    let pos = index;
    var charCode = event.which ? event.which : event.keyCode;
    if ((charCode < 48 || charCode > 57) && event.which != 8 && event.which != 229) {
      this.btnEnable = true;
      if (charCode == 9) {
        this.errorToShow = true;
        this.errorMsg = "This field is required";
        this.btnEnable = true;
      } else {
        this.errorToShow = false;
        this.errorMsg = "";
        this.btnEnable = false;
      }
    } else {
      if(event.key != 'Backspace'){
        // this.changePinFormLeap.get(`change${index + 1}`).setValue(event.key);
        var filterArr = this.arrVal.filter((res)=>{return res.key ==pos});
        if(filterArr.length > 0 && filterArr[0].value){
          for(let i=0; i< this.arrVal.length; i++){
            if(this.arrVal[i].key == filterArr[0].key + 1){
              this.arrVal[i].value = event.key;
              this.changePinFormLeap.get(`change${this.arrVal[i].key + 1}`).setValue(event.key);  
            }
            if(filterArr[0].key == 0 || filterArr[0].key && filterArr[0].key!=3){
              const position = this.arrVal.map(e => e.key).indexOf(filterArr[0].key+1);
              if(position>-1){
                this.arrVal[position].value= event.key;
              }else{
              this.arrVal.push(
                {
                  key : filterArr[0].key+1,
                  value : event.key
                }
               );
              }
              this.changePinFormLeap.get(`change${index+2}`).setValue(event.key);  
              break;
            }
            if(pos == 3 && this.arrVal[i].key == 3){
              this.arrVal[i].value = event.key;
              this.changePinFormLeap.get(`change${this.arrVal[i].key + 1}`).setValue(event.key);
            }
          }

        }else{
          this.arrVal.push(
            {
              key : pos,
              value : this.changePinFormLeap.get(`change${index + 1}`).value
            }
          );
          this.changePinFormLeap.get(`change${index + 1}`).setValue(this.changePinFormLeap.get(`change${index + 1}`).value);    
        }
      }else{
        if(this.arrVal.length > 0){
          const position = this.arrVal.map(e => e.key).indexOf(pos);
          if(position>-1){
             this.arrVal.splice(position, 1);
          }

        }
      }

      if (event.keyCode === 8 && event.which === 8) {
        this.btnEnable = true;
        pos = index - 1;
      } else {
        pos = index + 1;
      }
      if (pos > -1 && pos < 4) {
        this.errorToShow = true;
        this.errorMsg = "This field is required";
        this.btnEnable = true;
        setTimeout(()=>{
          document.getElementById('box'+pos).focus()
          //this.rows._results[pos].nativeElement.focus();
        },0)
      } else {
        this.btnEnable = true;
        this.errorToShow = false;
        this.errorMsg = "";
        this.validateSequenceLeap();
      }
      if (pos > 3 && this.changePinFormLeap.get("change4").value<0) {
        this.errorToShow = true;
        this.errorMsg = "This field is required";
        this.btnEnable = true;
      } else {
        this.errorToShow = false;
        this.errorMsg = "";
        this.btnEnable = false
      }
    }
    this.create_pin_length =
      this.changePinFormLeap.get("change1").value +
      "" +
      this.changePinFormLeap.get("change2").value +
      "" +
      this.changePinFormLeap.get("change3").value +
      "" +
      this.changePinFormLeap.get("change4").value;
      this.create_pin_length = this.create_pin_length.replace(null,'');
  }

  keyUpEventForCreatePinNassWallet(event, index) {
    let pos = index;
    var charCode = event.which ? event.which : event.keyCode;
    if ((charCode < 48 || charCode > 57) && event.which != 8 && event.which != 229) {
      this.btnEnable = true;
      if (charCode == 9) {
        this.errorToShow = true;
        this.errorMsg = "This field is required";
        this.btnEnable = true;
      } else {
        this.errorToShow = false;
        this.errorMsg = "";
        this.btnEnable = false;
      }
    } else {
      if(event.key != 'Backspace'){
        // this.changePinFormLeap.get(`change${index + 1}`).setValue(event.key);
        var filterArr = this.arrVal.filter((res)=>{return res.key ==pos});
        if(filterArr.length > 0 && filterArr[0].value){
          for(let i=0; i< this.arrVal.length; i++){
            if(this.arrVal[i].key == filterArr[0].key + 1){
              this.arrVal[i].value = event.key;
              this.changePinFormLeap.get(`change${this.arrVal[i].key + 1}`).setValue(event.key);  
            }
            if(filterArr[0].key == 0 || filterArr[0].key && filterArr[0].key!=3){
              const position = this.arrVal.map(e => e.key).indexOf(filterArr[0].key+1);
              if(position>-1){
                this.arrVal[position].value= event.key;
              }else{
              this.arrVal.push(
                {
                  key : filterArr[0].key+1,
                  value : event.key
                }
               );
              }
              this.changePinFormLeap.get(`change${index+2}`).setValue(event.key);  
              break;
            }
            if(pos == 3 && this.arrVal[i].key == 3){
              this.arrVal[i].value = event.key;
              this.changePinFormLeap.get(`change${this.arrVal[i].key + 1}`).setValue(event.key);
            }
          }

        }else{
          this.arrVal.push(
            {
              key : pos,
              value : this.changePinFormLeap.get(`change${index + 1}`).value
            }
          );
          this.changePinFormLeap.get(`change${index + 1}`).setValue(this.changePinFormLeap.get(`change${index + 1}`).value);    
        }
      }else{
        if(this.arrVal.length > 0){
          const position = this.arrVal.map(e => e.key).indexOf(pos);
          if(position>-1){
             this.arrVal.splice(position, 1);
          }

        }
      }

      if (event.keyCode === 8 && event.which === 8) {
        this.btnEnable = true;
        pos = index - 1;
      } else {
        pos = index + 1;
      }
      if (pos > -1 && pos < 4) {
        this.errorToShow = true;
        this.errorMsg = "This field is required";
        this.btnEnable = true;
        setTimeout(()=>{
          document.getElementById('circle'+pos).focus()
          //this.rows._results[pos].nativeElement.focus();
        },0)
      } else {
        this.btnEnable = true;
        this.errorToShow = false;
        this.errorMsg = "";
        this.validateSequenceLeap();
      }
      if (pos > 3 && this.changePinFormLeap.get("change4").value<0) {
        this.errorToShow = true;
        this.errorMsg = "This field is required";
        this.btnEnable = true;
      } else {
        this.errorToShow = false;
        this.errorMsg = "";
        this.btnEnable = false
      }
    }
    this.create_pin_length =
      this.changePinFormLeap.get("change1").value +
      "" +
      this.changePinFormLeap.get("change2").value +
      "" +
      this.changePinFormLeap.get("change3").value +
      "" +
      this.changePinFormLeap.get("change4").value;
  }

  keyUpEventForConfirmPinNassWallet(event, index) {
    let pos = index;
    var charCode = event.which ? event.which : event.keyCode;
    if ((charCode < 48 || charCode > 57) && event.which != 8 && event.which != 229) {
      this.btnEnable = true;
      if (charCode == 9) {
        this.errorToShow = true;
        this.errorMsg = "This field is required";
        this.btnEnable = true;
      } else {
        this.errorToShow = false;
        this.errorMsg = "";
        this.btnEnable = false;
      }
    } else {
      if(event.key != 'Backspace'){
        var filterArr1 = this.arrVal1.filter((res)=>{return res.key ==pos});
        if(filterArr1.length > 0 && filterArr1[0].value){
          for(let i=0; i< this.arrVal1.length; i++){
            if(this.arrVal1[i].key == filterArr1[0].key + 1){
              this.arrVal1[i].value = event.key;
              this.confirmPinFormLeap.get(`confirm${this.arrVal1[i].key + 1}`).setValue(event.key);  
            }
            if(filterArr1[0].key == 0 || filterArr1[0].key && filterArr1[0].key!=3){
              const position = this.arrVal1.map(e => e.key).indexOf(filterArr1[0].key+1);
              if(position>-1){
                this.arrVal1[position].value= event.key;
              }else{
              this.arrVal1.push(
                {
                  key : filterArr1[0].key+1,
                  value : event.key
                }
               );
              }
              this.confirmPinFormLeap.get(`confirm${index+2}`).setValue(event.key);  
              break;
            }
            if(pos == 3 && this.arrVal1[i].key == 3){
              this.arrVal1[i].value = event.key;
              this.confirmPinFormLeap.get(`confirm${this.arrVal1[i].key + 1}`).setValue(event.key);
            }
          }

        }else{
          this.arrVal1.push(
            {
              key : pos,
              value : this.confirmPinFormLeap.get(`confirm${index + 1}`).value
            }
          );
          this.confirmPinFormLeap.get(`confirm${index + 1}`).setValue(this.confirmPinFormLeap.get(`confirm${index + 1}`).value);    
        }
      }else{
        if(this.arrVal1.length > 0){
          const position = this.arrVal1.map(e => e.key).indexOf(pos);
          if(position>-1){
           this.arrVal1.splice(position, 1);
          }
        }
      }
      if (event.keyCode === 8 && event.which === 8) {
        this.btnEnable = true;
        pos = index - 1;
      } else {
        pos = index + 1;
      }
      if (pos > -1 && pos < 4) {
        this.errorToShow = true;
        this.errorMsg = "This field is required";
        this.btnEnable = true;
        setTimeout(()=>{
          document.getElementById('nasbox'+pos).focus()
          //this.rows1._results[pos].nativeElement.focus();
        },0)
        
      } else {
        this.btnEnable = true;
        this.errorToShow = false;
        this.errorMsg = "";
        this.validatePinLeap();
      }
      if (pos > 3 && this.confirmPinFormLeap.get("confirm4").value<0) {
        this.errorToShow = true;
        this.errorMsg = "This field is required";
        this.btnEnable = true;
      } else {
        this.errorToShow = false;
        this.errorMsg = "";
        this.btnEnable = false;
      }
    }
    this.confirm_pin_length =
      this.confirmPinFormLeap.get("confirm1").value +
      "" +
      this.confirmPinFormLeap.get("confirm2").value +
      "" +
      this.confirmPinFormLeap.get("confirm3").value +
      "" +
      this.confirmPinFormLeap.get("confirm4").value;
  }

  keyUpEventForConfirmPinZywa(event, index) {
    let pos = index;
    var charCode = event.which ? event.which : event.keyCode;
    if ((charCode < 48 || charCode > 57) && event.which != 8 && event.which != 229) {
      this.btnEnable = true;
      if (charCode == 9) {
        this.errorToShow = true;
        this.errorMsg = "This field is required";
        this.btnEnable = true;
      } else {
        this.errorToShow = false;
        this.errorMsg = "";
        this.btnEnable = false;
      }
    } else {
      if(event.key != 'Backspace'){
        var filterArr1 = this.arrVal1.filter((res)=>{return res.key ==pos});
        if(filterArr1.length > 0 && filterArr1[0].value){
          for(let i=0; i< this.arrVal1.length; i++){
            if(this.arrVal1[i].key == filterArr1[0].key + 1){
              this.arrVal1[i].value = event.key;
              this.confirmPinFormLeap.get(`confirm${this.arrVal1[i].key + 1}`).setValue(event.key);  
            }
            if(filterArr1[0].key == 0 || filterArr1[0].key && filterArr1[0].key!=3){
              const position = this.arrVal1.map(e => e.key).indexOf(filterArr1[0].key+1);
              if(position>-1){
                this.arrVal1[position].value= event.key;
              }else{
              this.arrVal1.push(
                {
                  key : filterArr1[0].key+1,
                  value : event.key
                }
               );
              }
              this.confirmPinFormLeap.get(`confirm${index+2}`).setValue(event.key);  
              break;
            }
            if(pos == 3 && this.arrVal1[i].key == 3){
              this.arrVal1[i].value = event.key;
              this.confirmPinFormLeap.get(`confirm${this.arrVal1[i].key + 1}`).setValue(event.key);
            }
          }

        }else{
          this.arrVal1.push(
            {
              key : pos,
              value : this.confirmPinFormLeap.get(`confirm${index + 1}`).value
            }
          );
          this.confirmPinFormLeap.get(`confirm${index + 1}`).setValue(this.confirmPinFormLeap.get(`confirm${index + 1}`).value);    
        }
      }else{
        if(this.arrVal1.length > 0){
          const position = this.arrVal1.map(e => e.key).indexOf(pos);
          if(position>-1){
           this.arrVal1.splice(position, 1);
          }
        }
      }
      if (event.keyCode === 8 && event.which === 8) {
        this.btnEnable = true;
        pos = index - 1;
      } else {
        pos = index + 1;
      }
      if (pos > -1 && pos < 4) {
        this.errorToShow = true;
        this.errorMsg = "This field is required";
        this.btnEnable = true;
        setTimeout(()=>{
          document.getElementById('cbox'+pos).focus()
          //this.rows1._results[pos].nativeElement.focus();
        },0)
        
      } else {
        this.btnEnable = true;
        this.errorToShow = false;
        this.errorMsg = "";
        this.validatePinLeap();
      }
      if (pos > 3 && this.confirmPinFormLeap.get("confirm4").value<0) {
        this.errorToShow = true;
        this.errorMsg = "This field is required";
        this.btnEnable = true;
      } else {
        this.errorToShow = false;
        this.errorMsg = "";
        this.btnEnable = false;
      }
    }
    this.confirm_pin_length =
      this.confirmPinFormLeap.get("confirm1").value +
      "" +
      this.confirmPinFormLeap.get("confirm2").value +
      "" +
      this.confirmPinFormLeap.get("confirm3").value +
      "" +
      this.confirmPinFormLeap.get("confirm4").value;
      this.confirm_pin_length = this.confirm_pin_length.replace(null,'');
  }

  // Confirm Pin End Here

  // End New Code For Pin

    // Myffin Code Start Here

    keyUpEventForCreatePinMyffin(event, index) {
      let pos = index;
      this.create_pin_length =
        this.changePinFormLeap.get("change1").value +
        "" +
        this.changePinFormLeap.get("change2").value +
        "" +
        this.changePinFormLeap.get("change3").value +
        "" +
        this.changePinFormLeap.get("change4").value;
      var charCode = event.which ? event.which : event.keyCode;
      if ((charCode < 48 || charCode > 57) && event.which != 8) {
        this.btnEnable = true;
        if (charCode == 9) {
          this.errorToShow = true;
          this.errorMsg = "This field is required";
          this.btnEnable = true;
        } else {
          this.errorToShow = false;
          this.errorMsg = "";
        }
      } else {
        if(event.key != 'Backspace'){
  
          this.changePinFormLeap.get(`change${index + 1}`).setValue(event.key);  
          this.arrVal.push(this.changePinFormLeap.get(`change${index + 1}`).value);
          if(this.arrVal.length > 3){
            this.arrVal[3] = event.key;
          }
          if(pos == 3){
            this.changePinFormLeap.get(`change${4}`).setValue(event.key);
            this.arrVal.splice(3, event.key);
          }else{
            if(this.arrVal.length > 3){
              if(pos == 0){
                this.changePinFormLeap.get(`change${ 1 }`).setValue(this.arrVal[0]);
                this.changePinFormLeap.get(`change${ 2 }`).setValue(event.key);
                this.arrVal[1] = event.key;
              }else if(pos == 1){
                this.changePinFormLeap.get(`change${ 2 }`).setValue(this.arrVal[1]);
                this.changePinFormLeap.get(`change${ 3 }`).setValue(event.key);
                this.arrVal[2] = event.key;
              }else if(pos == 2){
                this.changePinFormLeap.get(`change${ 3 }`).setValue(this.arrVal[2]);
                this.changePinFormLeap.get(`change${ 4 }`).setValue(event.key);
                this.arrVal[3] = event.key;
                this.arrVal.splice(3, 1);
              }else{
                this.changePinFormLeap.get(`change${4}`).setValue(event.key);
              }
            }
          }
        }
        else{
          if(pos > 0){
            this.arrVal.splice(pos, this.changePinFormLeap.get(`change${pos}`).value);
          }
          if(pos == 0){
            this.arrVal = [];
          }
        }
        if (event.keyCode === 8 && event.which === 8) {
          this.btnEnable = true;
          pos = index - 1;
        } else {
          pos = index + 1;
          if(this.arrVal.length == 2){
            this.changePinFormLeap.get(`change${ 1 }`).setValue(this.arrVal[0]);
            this.changePinFormLeap.get(`change${ 2 }`).setValue(this.arrVal[1]);
          }
          if(this.arrVal.length == 3){
            this.changePinFormLeap.get(`change${ 2 }`).setValue(this.arrVal[1]);
            this.changePinFormLeap.get(`change${ 3 }`).setValue(this.arrVal[2]);
          }
        }
        if (pos > -1 && pos < 4) {
          this.errorToShow = true;
          this.errorMsg = "This field is required";
          this.btnEnable = true;
          this.rows._results[pos].nativeElement.focus();
        } else {
          this.btnEnable = true;
          this.errorToShow = false;
          this.errorMsg = "";
          this.validateSequenceLeap();
        }
        if (pos > 3 && this.changePinFormLeap.get("change4").value == "") {
          this.errorToShow = true;
          this.errorMsg = "This field is required";
          this.btnEnable = true;
        } else {
          this.errorToShow = false;
          this.errorMsg = "";
        }
      }
    }
  
    keyUpEventForConfirmPinMyffin(event, index) {
      let pos = index;
      this.confirm_pin_length =
        this.confirmPinFormLeap.get("confirm1").value +
        "" +
        this.confirmPinFormLeap.get("confirm2").value +
        "" +
        this.confirmPinFormLeap.get("confirm3").value +
        "" +
        this.confirmPinFormLeap.get("confirm4").value;
      var charCode = event.which ? event.which : event.keyCode;
  
      if ((charCode < 48 || charCode > 57) && event.which != 8) {
        this.btnEnable = true;
        if (charCode == 9) {
          this.errorToShow = true;
          this.errorMsg = "This field is required";
          this.btnEnable = true;
        } else {
          this.errorToShow = false;
          this.errorMsg = "";
        }
      } else {
        if(event.key != 'Backspace'){
          // this.confirmPinFormLeap.get(`confirm${index + 1}`).setValue(event.key);
          this.arrVal1.push(this.confirmPinFormLeap.get(`confirm${index + 1}`).value);
          if(this.arrVal1.length > 3){
            this.arrVal1[3] = event.key;
          }
          if(pos == 3){
            this.confirmPinFormLeap.get(`confirm${4}`).setValue(event.key);
            this.arrVal1.splice(3, event.key);
          }else{
            if(this.arrVal1.length > 3){
              if(pos == 0){
                this.confirmPinFormLeap.get(`confirm${ 1 }`).setValue(this.arrVal1[0]);
                this.confirmPinFormLeap.get(`confirm${ 2 }`).setValue(event.key);
                this.arrVal1[1] = event.key;
              }else if(pos == 1){
                this.confirmPinFormLeap.get(`confirm${ 2 }`).setValue(this.arrVal1[1]);
                this.confirmPinFormLeap.get(`confirm${ 3 }`).setValue(event.key);
                this.arrVal1[2] = event.key;
              }else if(pos == 2){
                this.confirmPinFormLeap.get(`confirm${ 3 }`).setValue(this.arrVal1[2]);
                this.confirmPinFormLeap.get(`confirm${ 4 }`).setValue(event.key);
                this.arrVal1[3] = event.key;
                this.arrVal1.splice(3, 1);
              }else{
                this.confirmPinFormLeap.get(`confirm${4}`).setValue(event.key);
              }
            }
          }
        }
        else{
          if(pos > 0){
            this.arrVal1.splice(pos, this.confirmPinFormLeap.get(`confirm${pos}`).value);
          }
          if(pos == 0){
            this.arrVal1 = [];
          }
        }
        if (event.keyCode === 8 && event.which === 8) {
          this.btnEnable = true;
          pos = index - 1;
        } else {
          pos = index + 1;
          if(this.arrVal1.length == 2){
            this.confirmPinFormLeap.get(`confirm${ 1 }`).setValue(this.arrVal1[0]);
            this.confirmPinFormLeap.get(`confirm${ 2 }`).setValue(this.arrVal1[1]);
          }
          if(this.arrVal1.length == 3){
            this.confirmPinFormLeap.get(`confirm${ 2 }`).setValue(this.arrVal1[1]);
            this.confirmPinFormLeap.get(`confirm${ 3 }`).setValue(this.arrVal1[2]);
          }
        }
        if (pos > -1 && pos < 4) {
          this.errorToShow = true;
          this.errorMsg = "This field is required";
          this.btnEnable = true;
          this.rows1._results[pos].nativeElement.focus();
        } else {
          this.btnEnable = true;
          this.errorToShow = false;
          this.errorMsg = "";
          this.validatePinLeap();
        }
        if (pos > 3 && this.confirmPinFormLeap.get("confirm4").value == "") {
          this.errorToShow = true;
          this.errorMsg = "This field is required";
          this.btnEnable = true;
        } else {
          this.errorToShow = false;
          this.errorMsg = "";
        }
      }
    }

    backToChangePin(){
      // this.changePinFormLeap.reset();
      this.createPin = false;
    }
  
    // Myffin Code End Here


    // MamoPay Code Start Here
    mamoPinCreate(){
      this.isLoaded = true;
      var encode_pin = "";
      var pin = "";
      pin = this.changePinForm.value.change.toString();
      if (environment.env_type == "Production") {
        encode_pin = forge.util.pci_encrypt2(this.tenantID.public_key, pin);
      } else {
        encode_pin = forge.util.pci_encrypt2(environment.public_key, pin);
      }
      this.pciWidgetService
        .changePin(this.cardData.card_id, this.cardData.lut, encode_pin)
        .subscribe(
          (response) => {
              this.successMessage = "Card pin reset successfully";
              this.isLoaded = false;
          },
          (error) => {
              this.errMesaage = error.error.detail;
              this.isLoaded = false;
          }
        );
      }

      // NassWallet Code Start Here
    nassWalletPinCreate(){
      this.isLoaded = true;
      var encode_pin = "";
      var pin = "";
      pin = this.changePinForm.value.change.toString();
      if (environment.env_type == "Production" || environment.env_type == "Iq") {
        encode_pin = forge.util.pci_encrypt2(this.tenantID.public_key, pin);
      } else {
        encode_pin = forge.util.pci_encrypt2(environment.public_key, pin);
      }
      this.pciWidgetService
        .changePin(this.cardData.card_id, this.cardData.lut, encode_pin)
        .subscribe(
          (response) => {
              this.successMessage = "Card pin reset successfully";
              this.isLoaded = false;
              let payload = {
                type: "SUCCESS",
                message: "Card pin reset successfully",
              };
              window.parent.postMessage(JSON.stringify(payload), '*');
              this.postMessage(payload);
          },
          (error) => {
              this.errMesaage = error.error.detail;
              this.isLoaded = false;
              let payload = { type: "ERROR", message: error.error.detail };
              window.parent.postMessage(JSON.stringify(payload), '*');
              this.postMessage(payload);
          }
        );
      }

    hideShowMamo(status){
      if(status == 'setPin'){
        this.s_hide_show_mamo = !this.s_hide_show_mamo
          if(this.s_hide_show_mamo == true){
            document.getElementById('setMamo').setAttribute('type', 'password');
          }else{
            document.getElementById('setMamo').setAttribute('type', 'tel');
          }
        
      }else{
        this.c_hide_show_mamo = !this.c_hide_show_mamo
          if(this.c_hide_show_mamo == true){
            document.getElementById('confirmMamo').setAttribute('type', 'password');
          }else{
            document.getElementById('confirmMamo').setAttribute('type', 'tel');
          }
      }
    }

    hideShowNassWallet(status){
      if(status == 'setPin'){
        this.s_hide_show_mamo = !this.s_hide_show_mamo
          if(this.s_hide_show_mamo == true){
            document.getElementById('setNassWallet').setAttribute('type', 'password');
          }else{
           document.getElementById('setNassWallet').setAttribute('type', 'tel');
          }
        
      }else{
        this.c_hide_show_mamo = !this.c_hide_show_mamo
          if(this.c_hide_show_mamo == true){
            document.getElementById('confirmNassWallet').setAttribute('type', 'password');
          }else{
            document.getElementById('confirmNassWallet').setAttribute('type', 'tel');
          }
      }
    }
      // MamoPay Code End Here
    backToCreatePinNassWallet(){
      this.createPin = false;
      this.changePinFormLeap.reset();
      this.confirmPinFormLeap.reset();
      this.create_pin_length = '1';
      this.confirm_pin_length = '1';
      this.arrVal = [];
    }
    backToCreatePin(){
      this.createPin = false;
      this.changePinFormLeap.reset();
    }
    togglePassword(): void {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    }
}
