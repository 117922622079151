import { Component, OnInit } from "@angular/core";
import { PciWidgetService } from "../../services/pci-widget.service";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { environment } from "../../../environments/environment";
import * as forge from "../../services/forge.js";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: "app-card-get-pin",
  templateUrl: "./card-get-pin.component.html",
  styleUrls: ["./card-get-pin.component.scss"],
})
export class CardGetPinComponent implements OnInit {
  [x: string]: any;
  cardLut;
  showPin: any;
  errMesaage: any;
  tenantID: any;
  isLoaded: boolean = true;
  bgColor: string = "";
  txtColor: string = "";
  errColor: string = "";
  btnBgColor: string = "";
  btnTextColor: string = "";
  arabicText:boolean = false;
  constructor(
    public pciWidgetService: PciWidgetService,
    public router: Router,
    private _route: ActivatedRoute,
    private translate: TranslateService,
  ) {
    translate.setDefaultLang('en');
  }

  ngOnInit() {
    this.isLoaded = true;
    this._route.paramMap.subscribe((params: ParamMap) => {
      var cardData = JSON.parse(atob(params.get("token")));
      this.bgColor          = cardData['background-color']; 
      this.txtColor         = cardData['text-color']
      this.errColor         = cardData['error-color'];
      this.btnBgColor       = cardData['button-background-color'];
      this.btnTextColor     = cardData['button-text-color'];
      let lang = cardData['lang'];
      if(lang != ''){
        if(lang == 'en'){
          this.arabicText = false;
        }else{
          this.arabicText = true;
        }
        this.translate.setDefaultLang(lang);
      }
      this.pciWidgetService
        .getTenenatId(cardData.card_id, cardData.lut, cardData.source_id)
        .subscribe(
          (res) => {
            let client_details = environment.tenantDetails.filter(
              (tenant) => tenant.tenantID == res["tenant_id"]
            );
            
            if (client_details.length > 0) {
              this.tenantID = client_details[0];
            } else {
              this.tenantID = {
                tenantID: "default",
                clientName: "default",
                card_reveal_private_key: "",
                public_key: "",
                private_key: "",
              };
            }
            //this.tenantID = res['tenant_id']
            // this.isLoaded = false;
          },
          (error) => {
            this.isLoaded = false;
          }
        );
      this.getCardPin(cardData.card_id, cardData.lut);
    });
  }

  getCardPin(cardId, cardLut) {
    // this.isLoaded = true;
    var dec_pin = "";
    this.pciWidgetService.getCardPin(cardId, cardLut).subscribe(
      (res) => {
        var enc = JSON.parse(JSON.stringify(res.enc_pin));
        if (environment.env_type == "Production" || environment.env_type == "Ksa" || environment.env_type == "Pak" || environment.env_type == "Iq") {
          dec_pin = forge.util.pci_decrypt2(this.tenantID.private_key, enc);
        }else if (environment.env_type == "Egypt") {
          dec_pin = forge.util.pci_decrypt(this.tenantID.private_key, enc);
        } else {
          dec_pin = forge.util.pci_decrypt2(environment.private_key, enc);
        }
        if (this.tenantID && this.tenantID.clientName == "Alaanpay") {
          this.showPin = dec_pin.split("");
        } else {
          this.showPin = dec_pin;
          if(!this.showPin){
            let payload = { type: "ERROR", message: 'Pin Retry Failed Try Again'};
            this.postMessage(payload);
          }
        }
        this.isLoaded = false;
      },
      (error) => {
        if (this.tenantID && this.tenantID.clientName == "Savii") {
          this.errMesaage = "";
          let payload = { type: "ERROR", message: error.error.detail };
          window.parent.postMessage(JSON.stringify(payload), window.origin);
          this.isLoaded = false;
        } else if (this.tenantID && this.tenantID.clientName == "Zywa") {
          this.errMesaage = "";
          let payload = { type: "ERROR", message: error.error.detail };
          this.postMessage(payload);
          this.isLoaded = false;
        } else if (this.tenantID && this.tenantID.clientName == "Bayblos" || this.tenantID.clientName == "Beyond" || this.tenantID.clientName == "Neo" || this.tenantID && this.tenantID.clientName == "Hugo" || this.tenantID.clientName == "Fib") {
          this.errMesaage = "";
          let payload = { type: "ERROR", message: error.error.detail };
          this.postMessage(payload);
          this.isLoaded = false;
        } else {
          this.errMesaage = error.error.detail;
          this.isLoaded = false;
        }
      }
    );
  }
  onClipboardClick(showPin: any) {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(showPin).then(() => {
        this.show_toolTip = true,
        setTimeout(() => {
          this.show_toolTip = false;
        }, 2000);
      }).catch(err => {
        console.error('Could not copy text: ', err);
      });
    } else {
      console.error('Clipboard API not supported');
    }
  }

  // Ideal implementation
 postMessage(message) {
  // // 1. Relay the event back to the host application
  if (window["webkit"]) {
    window["webkit"].messageHandlers.widgetEventCallback.postMessage(message);
  } // iOS support

  if (window["Android"]) {
    // Object Android is available in the global namespace of the host frame
    window["Android"].widgetEventCallback(JSON.stringify(message));
  }
}
}
