import { Component, OnInit,Input } from '@angular/core';
import {PciWidgetService} from '../../services/pci-widget.service';

import { ActivatedRoute, ParamMap, Router } from '@angular/router';

import { environment } from '../../../environments/environment';

import * as forge from '../../services/forge.js';

import { FormBuilder, Validators, AbstractControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-card-tenant4',
  templateUrl: './card-tenant4.component.html',
  styleUrls: ['./card-tenant4.component.scss']
})
export class CardTenant4Component implements OnInit {
  @Input() cardActivateForm;
  @Input() card_detail;
  @Input() errorMessage;
  @Input() myStyles
  
  constructor() { }

  ngOnInit() { }

}

