import { Component, OnInit } from '@angular/core';

import {PciWidgetService} from '../../services/pci-widget.service';

import { ActivatedRoute, ParamMap, Router } from '@angular/router';

@Component({
  selector: 'app-card-list',
  templateUrl: './card-list.component.html',
  styleUrls: ['./card-list.component.scss']
})
export class CardListComponent implements OnInit {
  [x: string]: any;
  api_key;
  pci_dynamic_css:any;
  public cardsList : any;
  public lut_token = '';
  public lang:string = '';

  constructor(public pciWidgetService:PciWidgetService,
              public router:Router,
              private _route: ActivatedRoute,) {
  }

  ngOnInit() { 
    this._route.paramMap.subscribe((params: ParamMap) => {
       var apikey = JSON.parse(atob(params.get('api_key')));
       this.pci_dynamic_css = apikey;
       this.api_key =  apikey['api_key'];
       this.lang = apikey['lang'] ? apikey['lang'] : '';
       this.pciWidgetService.getCardsList(this.api_key).subscribe((cards)=>{
         this.cardsList = cards.data;
       })
    })
  }

   requestForLUT(cardId){
        this.pciWidgetService.getLUT(cardId,this.api_key).subscribe((lut)=>{
          this.lut_token = lut.token;
          this.getCardDetails(cardId,this.lut_token)
        })
      
  }


  requestForChangePinLUT(cardId){
       this.pciWidgetService.getLUTForChangePin(cardId,this.api_key).subscribe((lut)=>{
       this.lut_token = lut.token;
       this.getCardSetPinDetails(cardId,this.lut_token,this.api_key,this.lang)
     })
   
}

requestForPinLUT(cardId){
     this.pciWidgetService.getLUTForPin(cardId,this.api_key).subscribe((lut)=>{
     this.lut_token = lut.token;
     this.getCardShowPinDetails(cardId,this.lut_token,this.api_key,this.lang)
   })
 
}
  
     getCardDetails(card_id,lut_token){
      let pci_object = {
        'background-color':this.pci_dynamic_css['background-color'],
        'button-background-color':this.pci_dynamic_css['button-background-color'],
        'button-text-color':this.pci_dynamic_css['button-text-color'],
        'error-color':this.pci_dynamic_css['error-color'],
        'text-color':this.pci_dynamic_css['text-color'],
        'lut_token':lut_token
        }
       let pci_css = btoa(JSON.stringify(pci_object))
        this.router.navigate(['cardFrame',pci_css,card_id]);
     }

     getCardShowPinDetails(card_id,lut_token,api_key,lang){
      let pci_object = {
        'background-color':this.pci_dynamic_css['background-color'],
        'button-background-color':this.pci_dynamic_css['button-background-color'],
        'button-text-color':this.pci_dynamic_css['button-text-color'],
        'error-color':this.pci_dynamic_css['error-color'],
        'text-color':this.pci_dynamic_css['text-color'],
        'api_key':api_key,
        'lang':lang
     }
       let pci_css = btoa(JSON.stringify(pci_object))
       this.router.navigate(['cardShowPinFrame',lut_token,card_id,pci_css]);
     } 

     getCardSetPinDetails(card_id,lut_token,api_key,lang){
      let pci_object = {
        'background-color':this.pci_dynamic_css['background-color'],
        'button-background-color':this.pci_dynamic_css['button-background-color'],
        'button-text-color':this.pci_dynamic_css['button-text-color'],
        'error-color':this.pci_dynamic_css['error-color'],
        'text-color':this.pci_dynamic_css['text-color'],
        'api_key':api_key,
        'lang':lang
     }
       let pci_css = btoa(JSON.stringify(pci_object))
      this.router.navigate(['cardSetPinFrame',lut_token,card_id,pci_css]);
    }  
}
