import { Component, OnInit } from '@angular/core';

import {ConfigService} from '../../services/config.service';

import { interval } from 'rxjs';

import { Router, ActivatedRoute, ParamMap,ActivationStart } from '@angular/router';

@Component({
  selector: 'app-seperate-inline',
  templateUrl: './seperate-inline.component.html',
  styleUrls: ['./seperate-inline.component.scss']
})
export class SeperateInlineComponent implements OnInit {
  
  public cardDetails:any;
  public myStyles : any={};;
  public cardText : any = "Virtual Card";
  public clientId :any;
  

  constructor(public configService:ConfigService,public route:Router) { 
     interval(500).subscribe(x => {
       if(this.route.url.indexOf('/true')>-1){
         this.cardDetails={"card_number":'4207 3890 6715 2309',"cvv":'108',"exp":'07/23'}
         }
     });
  }



  ngOnInit() {
    this.route.events.subscribe(event =>{
      // console.log("event",event)
      if (event instanceof ActivationStart){
   		 console.log("testststststs",event.snapshot.params)
   		  if(event.snapshot.params.param1==1){
    		   this.clientId =event.snapshot.params.param1;
    		   var data = this.configService.getClientConfig(this.clientId)
                this.myStyles = data;
                console.log("this.myStyles",this.myStyles)
   		  }else if(event.snapshot.params.param1==2){
    		   this.clientId =event.snapshot.params.param1;
    		   var data = this.configService.getClientConfig(this.clientId)
                this.myStyles = data;
   		  }else if(event.snapshot.params.param1==3){
   		        this.clientId =event.snapshot.params.param1;
   		        var data = this.configService.getClientConfig(this.clientId)
                this.myStyles = data;
   		   }
        }
    })

    

     this.cardDetails={"card_number":'4207-XXXX-XXXX-XX09',"cvv":'XXX',"exp":'07/23'}
    
  }



}
