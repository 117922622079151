import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-form-widget',
  templateUrl: './card-form-widget.component.html',
  styleUrls: ['./card-form-widget.component.scss']
})
export class CardFormWidgetComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }


  

}
