import {Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-card-details-zywa',
  templateUrl: './card-details-zywa.component.html',
  styleUrls: ['./card-details-zywa.component.scss']
})
export class CardDetailsZywaComponent implements OnInit {

  @Input() cardActivateForm;
  @Input() card_detail;
  @Input() errorMessage;
  @Input() myStyles;
  @Input() card_number;
  card1;
  card2;
  card3;
  card4;

  constructor() { }

  ngOnInit() {
    // console.log(this.card_detail.card_number.split(" "),'Hello');
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(this.card_detail.card_number){
      var card = this.card_detail.card_number.split(" ");
      this.card1 = card[0];
      this.card2 = card[1];
      this.card3 = card[2];
      this.card4 = card[3];
    }
    // console.log(this.card1,'sdasdas')
  }

}
