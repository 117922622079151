
import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';

import { FormBuilder, FormGroup,FormControl,AbstractControl,FormGroupDirective, Validators } from '@angular/forms';
import {ConfigService} from '../../services/config.service';

import { Router, ActivatedRoute, ParamMap,ActivationStart } from '@angular/router';

@Component({
  selector: 'app-card-widget',
  templateUrl: './card-widget.component.html',
  styleUrls: ['./card-widget.component.scss']
})
export class CardWidgetComponent implements OnInit {


  public cardDetails:any={};
  public myStyles : any={};
  public clientId:any;
  public activateCardPinForm: FormGroup;

  constructor(private fb: FormBuilder,public configService:ConfigService,public route:Router) { }

  ngOnInit() {
    this.activateCardPinForm = this.fb.group({
         cvv: ['', [Validators.required,
               Validators.minLength(6),
               Validators.maxLength(6),
               Validators.pattern(/^[0-9]*$/),
               ]],
        ccvv: ['', [Validators.required,
               Validators.minLength(6),
               Validators.maxLength(6),
               Validators.pattern(/^[0-9]*$/),
               ]],       
      })
  }

  submitCard(){
    this.configService.submitActivateCardAction(true)
  }

}
