import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-card-details-creditper',
  templateUrl: './card-details-creditper.component.html',
  styleUrls: ['./card-details-creditper.component.scss']
})
export class CardDetailsCreditperComponent implements OnInit {

  showText = true;
  card_num_show = 'XXXX XXXX XXXX XXXX';
  exp = 'XX/XX';
  cvv = 'XXX';

  @Input() cardActivateForm;
  @Input() card_detail;
  @Input() errorMessage;
  @Input() myStyles;
  @Input() card_number;

  constructor() { }

  toggleShow(){
    this.showText = !this.showText;
    this.toggleNumber();
    this.toggleExpiry();
    this.toggleCvv();
  }

  toggleNumber(){
    this.card_num_show = '';
    if(!this.showText){
      let number = this.card_detail.card_number.split(' ');
      number.forEach((num,index) => {
        console.log(num,'1');
        console.log(index,'2')
        if(index < number.length - 2){
          this.card_num_show += ' '+num
        // }
          // this.card_num_show += num.replace(num,'****');
        }else{
          this.card_num_show += num.replace(num,' ****');
          // this.card_num_show += num;
          // console.log(this.card_num_show,'2')
        }
      });
    }else{
      this.card_num_show = this.card_detail.card_number;
    }
  }

  toggleExpiry(){
    this.exp = '';
    this.exp = this.card_detail.exp;
    if(!this.showText){
      let expiry = this.card_detail.exp.split('/');
      this.exp = expiry[0].replace(expiry[0],'**')+'/'+expiry[1].replace(expiry[1],'**');
    }else{
      this.exp = this.card_detail.exp;
    }
  }

  toggleCvv(){
    this.cvv = '';
    this.cvv = this.card_detail.cvv;
    if(!this.showText){
      this.cvv = this.card_detail.cvv.replace(this.card_detail.cvv,'***');
    }else{
      this.cvv = this.card_detail.cvv;
    }
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes){
      if(this.card_detail.card_number != undefined){
        this.toggleNumber();
        this.toggleExpiry();
        this.toggleCvv();
      }
      // this.exp = this.card_detail.exp;
      // this.cvv = this.card_detail.cvv;
    }
  }

}
