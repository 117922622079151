import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup,FormControl,AbstractControl,FormGroupDirective, Validators } from '@angular/forms';
import {ConfigService} from '../../services/config.service';
import { Router, ActivatedRoute, ParamMap,ActivationStart } from '@angular/router';
import * as forge from '../../services/forge.min.js';
import {PciWidgetService} from '../../services/pci-widget.service';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-card-activate',
  templateUrl: './card-activate.component.html',
  styleUrls: ['./card-activate.component.scss']
})
export class CardActivateComponent implements OnInit {
 
  @ViewChild('ccNumber', {static: false}) ccNumberField: ElementRef;

  public cardDetails:any={};
  public myStyles : any={};
  public activateCardForm: FormGroup;
  public errMesaage:any;
  public isLoaded:boolean=true;
  clientId:any;
  
  
  constructor(private fb: FormBuilder,
              public configService:ConfigService,
              public route:Router,
              private _route: ActivatedRoute,
              private pciWidgetService:PciWidgetService) { }

  ngOnInit() { 
    this.isLoaded = true;
    this.activateCardForm = this.fb.group({
    pan: ['', [Validators.required,
               Validators.minLength(17),
               Validators.pattern(/^[0-9_ ]*$/),
               this.luhn_validate]],
    exp: ['', [Validators.required,
               Validators.minLength(2),
               Validators.maxLength(4),
               Validators.pattern(/^(0\d|1[0-2])\/\d{2}$/),
               this.validateExpiry]],
    cvv: ['', [Validators.required,
               Validators.minLength(3),
               Validators.maxLength(3),
               Validators.pattern(/^[0-9]*$/),
               ]],
   }) 

  

    // var url = (window.location != window.parent.location)
    //         ? document.referrer
    //         : document.location.href;
    //         console.log("url",url,)
  
    this._route.paramMap.subscribe((params: ParamMap) => {
           var cardData = JSON.parse(atob(params.get('token')))
           this.pciWidgetService.getCardDetails(cardData.card_id,cardData.lut,cardData.source_id).subscribe((cardDetails)=>{
           var encryptCard    = JSON.parse(JSON.stringify(cardDetails));
           var decryptCvcData =    forge.util.pci_decrypt(environment.private_key, encryptCard['enc_cvc']);
           var decryptCardNumberData =    forge.util.pci_decrypt(environment.private_key, encryptCard['enc_number']);
           var decrypMonthtData =    forge.util.pci_decrypt(environment.private_key, encryptCard['enc_exp_month']);
           var decrypYEartData  =    forge.util.pci_decrypt(environment.private_key, encryptCard['enc_exp_year']);
           this.cardDetails['cvv'] = decryptCvcData.data;
           this.cardDetails['card_number'] = decryptCardNumberData.data;
           this.cardDetails['exp'] = decrypMonthtData.data+this.getYearDigit(decrypYEartData.data);
           this.cardDetails['card_number'] = this.cc_format(this.cardDetails['card_number'])
           this.cardDetails['exp'] = this.cc_expires_format(this.cardDetails['exp'])
           this.isLoaded = false;     
          },(error) => {
              this.errMesaage = error.error.detail;
              this.isLoaded=false;
          })
       // this.myStyles = this.configService.getClientConfig(1)
     });

 

  this.route.events.subscribe(event =>{
      if (event instanceof ActivationStart){
        if(event.snapshot.params.param1==1){
           this.clientId =event.snapshot.params.param1;
           var data = this.configService.getClientConfig(this.clientId)
                this.myStyles = data;
        }else if(event.snapshot.params.param1==2){
           this.clientId =event.snapshot.params.param1;
           var data = this.configService.getClientConfig(this.clientId)
                this.myStyles = data;
        }else if(event.snapshot.params.param1==3){
             this.clientId =event.snapshot.params.param1;
              var data = this.configService.getClientConfig(this.clientId)
                this.myStyles = data;
        }
      }
   })

  }


  cc_format(value) {
    var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
    var matches = v.match(/\d{4,16}/g);
    var match = matches && matches[0] || ''
    var parts = []
    var len:any=0

    for (var i=0, len=match.length; i<len; i+=4) {
        parts.push(match.substring(i, i+4))
    }

    if (parts.length) {
        return parts.join(' ')
    } else {
        return value
    }
}

getYearDigit(year){
  return  year.toString().substr(-2);
}
   groupParamsByKey = (params) => [...params.entries()].reduce((acc, tuple) => {
    // getting the key and value from each tuple
    const [key, val] = tuple;
    if(acc.hasOwnProperty(key)) {
       // if the current key is already an array, we'll add the value to it
       if(Array.isArray(acc[key])) {
         acc[key] = [...acc[key], val]
       } else {
         // if it's not an array, but contains a value, we'll convert it into an array
         // and add the current value to it
         acc[key] = [acc[key], val];
       }
    } else {
     // plain assignment if no special case is present
     acc[key] = val;
    }
   
   return acc;
   }, {});


   

  validateExpiry(control:AbstractControl) {
  // ensure basic format is correct
  if(!control.value){
    return { 'expPatteren': false};
  }
  if(control.value.length ==0) return {'expPatteren': false}
  if (control.value && control.value.match(/^(0\d|1[0-2])\/\d{2}$/)) {
    const {0: month, 1: year} = control.value.split("/");
    let years = "20"+year;

    // get midnight of first day of the next month
    const expiry = new Date(parseInt(years), month);

    const current = new Date();
    
    return expiry.getTime() > current.getTime() ? {'expPatteren': false} :  {'expPatteren': true};
    
  } else return {'expPatteren': true}
}

cc_expires_format(string) {
  return string.replace(
      /[^0-9]/g, '' // To allow only numbers
  ).replace(
      /^([2-9])$/g, '0$1' // To handle 3 > 03
  ).replace(
      /^(1{1})([3-9]{1})$/g, '0$1/$2' // 13 > 01/3
  ).replace(
      /^0{1,}/g, '0' // To handle 00 > 0
  ).replace(
      /^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g, '$1/$2' // To handle 113 > 11/3
  );
}

 luhn_validate(control:AbstractControl){
 let value = control.value;
 if(!control.value){
    return false;
 }
   if (control.value.length==0) return  {'validCard':true};
   if (/[^0-9-\s]+/.test(value)) return {'validCard':true};

	// The Luhn Algorithm. It's so pretty.
	let nCheck = 0, bEven = false;
	value = value.replace(/\D/g, "");

	for (var n = value.length - 1; n >= 0; n--) {
		var cDigit = value.charAt(n),
			  nDigit = parseInt(cDigit, 10);

		if (bEven && (nDigit *= 2) > 9) nDigit -= 9;

		nCheck += nDigit;
		bEven = !bEven;
  }
  

	return (nCheck % 10) == 0 ? {'validCard':false} : {'validCard':true}
};

cardNumberSpacing(cardNo) {
      const { cardNumber } = this.cardDetails.card_number;
    let trimmedCardNum = cardNo.replace(/\s+/g, '');
    if (trimmedCardNum.length >= 16) {
        trimmedCardNum = trimmedCardNum.substr(0, 16);
    }

     /* Handle American Express 4-6-5 spacing format */
    const partitions = trimmedCardNum.startsWith('34') || trimmedCardNum.startsWith('37') 
                       ? [4,6,5] 
                       : [4,4,4,4];

    const numbers = [];
    let position = 0;
    partitions.forEach(partition => {
      const part = trimmedCardNum.substr(position, partition);
      if (part) numbers.push(part);
      position += partition;
    })
     this.activateCardForm.controls['pan'].setValue(numbers.join(' '));
    //this.activateCardForm.pan.setValue(numbers.join(' '));

    /* Handle caret position if user edits the number later */
    if(this.ccNumberField){
      const input = this.ccNumberField.nativeElement;
      const { selectionStart } = input;

      if (selectionStart < cardNumber.value.length - 1) {
        input.setSelectionRange(selectionStart, selectionStart, 'none');
      }
    
    }
    

  }

  submitCard(){
    this.configService.submitActivateCardAction({"isSubmit":true})
  }

}
