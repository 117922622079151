import {Component, Input, OnChanges, OnInit, SimpleChanges,ViewChild } from '@angular/core';

@Component({
  selector: 'app-card-details-easytip',
  templateUrl: './card-details-easytip.component.html',
  styleUrls: ['./card-details-easytip.component.scss']
})
export class CardDetailsEasytipComponent implements OnInit {

  showText = false;
  alertMessage = '';
  counter = 0;
  cardValue;
  card_rem_digit;
  card_holder_name = 'xxxxxxxxx';
  card_num_show = 'xxxx xxxx xxxx xxxx';
  exp = 'xx/xx';
  cvv = 'xxx';
  public card_name1: boolean = false;
  public card_num1: boolean = false;
  public card_exp1: boolean = false;
  public card_cvv1: boolean = false;
  public expiry: boolean = false;
  public security_code: boolean = false;
  @Input() cardActivateForm;
  @Input() card_detail;
  @Input() errorMessage;
  @Input() myStyles;
  @Input() card_number;

  constructor() { }

  ngOnInit() {
  }

  copyToClipboard(item,value): void {
    if(value == 'card_name'){
      this.card_name1 = true;
      this.card_num1 = false;
      this.card_exp1 = false;
      this.card_cvv1 = false;
    }else if(value == 'card_exp'){
      this.card_exp1 = true;
      this.card_name1 = false;
      this.card_num1 = false;
      this.card_cvv1 = false;
    }else if(value == 'card_cvv'){
      this.card_cvv1 = true;
      this.card_exp1 = false;
      this.card_name1 = false;
      this.card_num1 = false;
    }else{
      this.card_num1 = true;
      this.card_name1 = false;
      this.card_exp1 = false;
      this.card_cvv1 = false;
    } 
    var info = '';
    if(this.card_name1 == true){
      info = item;
      navigator.clipboard.writeText(info);
      setTimeout(()=>{
        this.card_name1 = false;
        this.card_num1 = false;
        this.card_exp1 = false;
        this.card_cvv1 = false;
      }, 1000);
    }else if(this.card_num1 == true){
      info = item;
      navigator.clipboard.writeText(info.replace(/\s/g, ''));
      setTimeout(()=>{
        this.card_name1 = false;
        this.card_num1 = false;
        this.card_exp1 = false;
        this.card_cvv1 = false;
      }, 1000);
    }
    else{
      item.select();
      document.execCommand('copy');
      item.setSelectionRange(0, 0);
      navigator.clipboard.writeText(item.value.replace(/\s/g, ''));
        setTimeout(()=>{
          this.card_name1 = false;
          this.card_num1 = false;
          this.card_exp1 = false;
          this.card_cvv1 = false;
        }, 1000);
    }
  }

  toggleShow(){
    this.showText = !this.showText;
  }

}
