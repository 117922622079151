import { Injectable} from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { HttpHeaders,HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';

import { catchError, retry } from 'rxjs/operators';

import { environment } from '../../environments/environment';




const httpOptions = {
    headers: new HttpHeaders({
     "Apikey": 'tenant1-key',
    })
 };

@Injectable({
  providedIn: 'root'
})
export class PciWidgetService {
  public environment_url:any;

  constructor(private http: HttpClient) {
    this.environment_url = environment.api_url;
   }

   getCardsList(api_key){
     let httpOptions = {headers: new HttpHeaders({"Apikey": api_key }) };
     return this.http.get<any>(this.environment_url + 'v1/cards?limit=100&after=', httpOptions);
   }

   getCardDetails(card_id,lut_token,source_id){
    return this.http.get<any>(this.environment_url + 'public/v1/cards/'+card_id+'/getDetails?source_id='+environment.source_id+'&lut='+encodeURIComponent(lut_token));
  }

  getTenenatId(card_id,lut_token,source_id){
    return this.http.get<any>(this.environment_url + 'public/v1/cards/'+card_id+'/getTenant?source_id='+environment.source_id+'&lut='+encodeURIComponent(lut_token));
  }

  getLUT(card_id,api_key){
    let httpOptions = {headers: new HttpHeaders({"Apikey": api_key}) };
    let payload={ 
        "source_id": environment.source_id,
        "operation": "SHOW_CLEAR_CARD_DETAILS"
      }
     return this.http.post<any>(this.environment_url+ `v1/cards/${card_id}/createLut`,payload,httpOptions);
  }
  // Asif Added Service

  getLUTForPin(card_id,api_key){
    let httpOptions = {headers: new HttpHeaders({"Apikey": api_key}) };
    let payload={ 
        "source_id": environment.source_id,
        "operation": "GET_PIN"
      }
     return this.http.post<any>(this.environment_url+ `v1/cards/${card_id}/createLut`,payload,httpOptions);
  }

  getLUTForChangePin(card_id,api_key){
    let httpOptions = {headers: new HttpHeaders({"Apikey": api_key}) };
    let payload={ 
        "source_id": environment.source_id,
        "operation": "CHANGE_PIN"
      }
     return this.http.post<any>(this.environment_url+ `v1/cards/${card_id}/createLut`,payload,httpOptions);
  }

  getCardPin(card_id,cardLut) { 
    return this.http.get<any>(this.environment_url + 'public/v1/cards/' + card_id + '/getPin?source_id='+environment.source_id+'&lut='+encodeURIComponent(cardLut));
    
  }
  changePin(card_id,cardLut,encode_pin) { 
    let payload = {
      "enc_pin": encode_pin,
      "lut":cardLut,
      "source_id":environment.source_id


    };
    return this.http.post<any>(this.environment_url + 'public/v1/cards/'+card_id +'/changePin?source_id='+environment.source_id,payload);
  }
// END HERE

   handleError(error: HttpErrorResponse) {

    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);

    } else {
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    return throwError(error);
  }

}
