import {Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-card-details-akeedpay',
  templateUrl: './card-details-akeedpay.component.html',
  styleUrls: ['./card-details-akeedpay.component.scss']
})
export class CardDetailsAkeedpayComponent implements OnInit {

  showText = false;
  alertMessage = '';
  counter = 0;
  cardValue;
  card_rem_digit;
  card_num_show = 'xxxx xxxx xxxx xxxx';
  exp = 'xx/xx';
  cvv = 'xxx';
  public card_num: boolean = false;
  public expiry: boolean = false;
  public card_cvv:boolean = false;
  public card_name:boolean = false;
 
  @Input() cardActivateForm;
  @Input() card_detail;
  @Input() errorMessage;
  @Input() myStyles;

  constructor() { }


  ngOnInit() {
  }
    copyToClipboard(item,value): void {
  
    if(value === 'card_num'){
      this.card_num = true;
      this.card_name = false;
    }else if(value === 'expiry'){
      this.expiry = true;
      this.card_name = false;
    }else if(value === 'card_name'){
      this.card_name = true;
      this.card_num = false;
    }else{
      this.card_cvv = true;
      this.card_name = false;
    }   
    item.select();
    document.execCommand('copy');
    item.setSelectionRange(0, 0);
    navigator.clipboard.writeText(item.value.replace(/\s/g, ''));
      setTimeout(()=>{
            this.card_name = false;
            this.card_num = false;
            this.expiry = false;
            this.card_cvv = false;
          }, 1000);
    }

}
