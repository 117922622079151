import { Component, OnInit } from '@angular/core';

declare const VGSCollect:VGSCollectInterface

interface VGSFormInterface{
  field: (data: string, obj: {}) => {};
  submit: (data: string, obj: {}, func: (status, responce) => void) => VGSFormInterface;
}
interface VGSCollectInterface {
  create: (data: string, func: (state) => void) => VGSFormInterface;
}

@Component({
  selector: 'app-vgs-widget',
  templateUrl: './vgs-widget.component.html',
  styleUrls: ['./vgs-widget.component.scss']
})
export class VgsWidgetComponent implements OnInit {
  name = 'Angular';
  secureForm: VGSFormInterface;
  response: string[] = [];
  state: string = '';

  ngOnInit(){
    var vault = 'tntyq8efej5';
    this.secureForm = VGSCollect.create(vault, (state) => {
            console.log(state);
    this.state = JSON.stringify(state, null, '\t').trim();
    });


//const payment_form = VGSCollect.create("tntyq8efej5", function(state) {});

const styles = {
  background: "#FFFFFF",
  border: "1px solid #CED7E6",
  boxSizing: "border-box",
  borderRadius: "4px",
  height: "40px",
  padding: "0 16px",
};


var field = this.secureForm.field("#cc-holder .field-space", {
  type: "text",
  name: "card_holder",
  placeholder: 'Card holder',
  validations: ["required"],
  css: styles
});

this.secureForm.field("#cc-number .field-space", {
  type: "card-number",
  name: "card_number",
  showCardIcon: true,
  successColor: '#4F8A10',
  errorColor: '#D8000C',
  placeholder: 'Card number',
  validations: ["required", "validCardNumber"],
  css: styles
});

this.secureForm.field("#cc-cvc .field-space", {
  type: "card-security-code",
  name: "card_cvc",
  successColor: '#4F8A10',
  errorColor: '#D8000C',
  placeholder: 'CVC',
  maxLength: 3,
  validations: ["required", "validCardSecurityCode"],
  css: styles
});

this.secureForm.field("#cc-expiration-date .field-space", {
  type: "card-expiration-date",
  name: "card_exp",
  successColor: '#4F8A10',
  errorColor: '#D8000C',
  placeholder: 'MM / YY',
  validations: ["required", "validCardExpirationDate"],
  css: styles
});

}

submitForm(){
    this.secureForm.submit('/post', {},(status, response) =>{ 
    console.log("responses",response);
    this.response.push(JSON.stringify(response, null, ' '))
    });
  }

/*document.getElementById("payment-form-submit").addEventListener(
  "click",
  function(e) {
    e.preventDefault();
    payment_form.submit(
      "/post",
      {
        headers: {
          "x-custom-header": "Oh yes. I am a custom header"
        }
      },
      function(status, data) {
        let elem = document.getElementsByClassName("payment-success")[0];
        elem.classList.remove("hidden");
      },
      function(errors) {}
    );
  },
  false
);*/





 /*var field = this.secureForm.field('#cc-name .fake-input', {
      type: 'text',
      name: 'card_name',
      color: '333333',
      placeholder: 'Credit card holder name',
      validations: ['required'],
    });*/

    /*this.secureForm.field('#cc-number .fake-input', {
      type: 'card-number',
      name: 'card_number',
      successColor: 'green',
      errorColor: 'red',
      placeholder: 'Credit card number',
      validations: ['required', 'validCardNumber'],
    });*/

   /*this.secureForm.field('#cc-cvc .fake-input', {
      type: 'card-security-code',
      name: 'card_cvc',
      placeholder: 'CVC',
      validations: ['required', 'validCardSecurityCode'],
    });*/

    /*this.secureForm.field('#cc-expiration-date .fake-input', {
      type: 'card-expiration-date',
      name: 'card_exp',
      placeholder: 'Expiration date',
      validations: ['required', 'validCardExpirationDate']
    });*/
  //}
  /*submitForm(){
    this.secureForm.submit('/post', {},(status, response) =>{ 
    console.log("responses",response);
    this.response.push(JSON.stringify(response, null, ' '))
    });
  }*/
}

