import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-card-details-savii',
  templateUrl: './card-details-savii.component.html',
  styleUrls: ['./card-details-savii.component.scss']
})
export class CardDetailsSaviiComponent implements OnInit {

  
  card_num_show;
  

  public card_num: boolean = false;
  public expiry: boolean = false;
  public cvv: boolean = false;

  @Input() cardActivateForm;
  @Input() card_detail;
  @Input() errorMessage;
  @Input() myStyles;
  @Input() card_number;

  constructor() { }

  onClipboardClick(item,value): void {
    if(value == 'card_num'){
      this.card_num = true;
    }else{
      this.expiry = true;
    }  

    if(!this.card_detail.plan_number){
       this.card_detail.plan_number ='';
     }
      item.select();
      item.setSelectionRange(0, 99999);
       if(value=='card_num'){
         navigator.clipboard.writeText(this.card_detail.plan_number.replace(/\s/g, ''))
       }else{
         navigator.clipboard.writeText(item.value.replace(/\s/g, ''));
       }
       document.execCommand('copy')
     
      //navigator.clipboard.writeText(this.card_detail.plan_number);
      setTimeout(()=>{
            this.card_num = false;
            this.expiry = false;
          }, 1000);
          let payload = {"type":'COPY',"message":'Copied To Clipboard'};
          window.parent.postMessage(JSON.stringify(payload),window.origin);    

  }

  ngOnInit() {
  }

}
