import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InlineWidgetComponent } from './components/inline-widget/inline-widget.component';
import { CardWidgetComponent } from './components/card-widget/card-widget.component';
import { CardFormWidgetComponent } from './components/card-form-widget/card-form-widget.component';
import { SeperateInlineComponent } from './components/seperate-inline/seperate-inline.component';
import { CardIframeComponent } from './components/card-iframe/card-iframe.component';
import { CardActivateComponent } from './components/card-activate/card-activate.component';
import { CardListComponent } from './components/card-list/card-list.component';
import { RevealCardDetailsComponent } from './components/reveal-card-details/reveal-card-details.component';
import { CardGetPinComponent } from './components/card-get-pin/card-get-pin.component';
import { ChangeCardPinComponent} from './components/change-card-pin/change-card-pin.component'
import { CardShowPinFrameComponent } from './components/card-show-pin-frame/card-show-pin-frame.component';
import { CardSetPinFrameComponent } from './components/card-set-pin-frame/card-set-pin-frame.component';

const routes: Routes = [
  { path: 'cards/:api_key', component: CardListComponent },
  { path: 'inline/:token', component: InlineWidgetComponent },
  { path: 'cardFrame/:token/:card_id', component: CardIframeComponent },
  { path: 'cardShowPinFrame/:token/:card_id/:api_key', component: CardShowPinFrameComponent },
  { path: 'cardSetPinFrame/:token/:card_id/:api_key', component: CardSetPinFrameComponent },
  { path: 'card-activate/:param1/:param2',component:CardActivateComponent},
  { path: 'card-activate/:token',component:CardActivateComponent},
  { path: 'reveal-card-details/:token', component: RevealCardDetailsComponent },
  // Asif Hameed Path Added
  { path: 'get-card-pin/:token', component: CardGetPinComponent },
  { path: 'change-card-pin/:token', component: ChangeCardPinComponent },
  // End HERE
  { path: 'seperateFrame/:param1/:param2', component: SeperateInlineComponent },
  { path: '',   redirectTo: 'cards', pathMatch: 'full' }, // redirect to `first-component`
  { path: '**', component:  CardListComponent},
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

 
 }
